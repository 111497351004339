'use client';

import React, { useState, useEffect } from 'react';
import './Popup.css';
import logoPopup from '../../assets/images/logo-popup.png';

const Popup: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  if (!isOpen) return null;

  return (
    <div className="popupOverlay">
      <div className="popup">
        <div className="popupLogo">
          <img src={logoPopup} alt="Banner Responsivo" className="logoPopup" />
        </div>
        <div className="popupConteudo">
          <div className="popupClose">
            <h2>Atenção Clientes</h2>
            <button onClick={() => setIsOpen(false)}>
              <strong>X</strong>
            </button>
          </div>
          <p>
            A partir de <strong>06/03/2025</strong>, nossos{' '}
            <strong>Cartões Pré-pagos</strong> serão descontinuados para novas
            vendas e recargas.
          </p>
          <p>
            Você poderá utilizar seu saldo normalmente para compras e saques até{' '}
            <strong>30/04/2025</strong>.
          </p>
          <p>Após essa data, apenas a venda dos saldos será permitida.</p>
          <p>
            Mas temos uma solução ainda melhor para você:{' '}
            <strong>
              {' '}
              a Conta Global,{' '}
              <a
                href="https://om.ourominas.com/contaglobal"
                target="_blank"
                rel="noopener noreferrer"
              >
                clique aqui e saiba mais!
              </a>
            </strong>
          </p>
          <p>
            Ourominas. <strong>Você pode confiar!</strong>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Popup;
