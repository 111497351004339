import {  setUserStorage } from "../models/UserStorage";
import { setUserAuthStorageTravel } from "../models/modules/auth/UserAuthStorage";
import { setClienteCPF } from "../services/modules/cliente/clienteObservable";

export async function SetLoginStorage(data) {
  await localStorage.setItem("id", data.id);
  await localStorage.setItem("username", data.username);
  await localStorage.setItem("role", data.role);
  await localStorage.setItem("token", data.token);
  await localStorage.setItem("date", Date.now().toString())
}

export function LogoutLoginStorage() {
  localStorage.removeItem("id");
  localStorage.removeItem("username");
  localStorage.removeItem("role");
  localStorage.removeItem("token");
  localStorage.removeItem("date");
}

export function getUserLogged() {
  const dateString = localStorage.getItem("date");
  const login = localStorage.getItem("username");

  if (login && dateString) {
    const date = new Date(parseInt(dateString));
    const currentDate = new Date();

    //comparando se a data de Login é superior à 3 horas
    const timeDifferenceInMilliseconds = currentDate - date;
    const timeDifferenceInHours = timeDifferenceInMilliseconds / (1000 * 60 * 60); // Convertendo milissegundos para horas

    if (timeDifferenceInHours > 2) {
      return false;
    }
    return true;
  }

}
export function getUsername() {
  return localStorage.getItem("username");
}

export function getToken() {
  return localStorage.getItem("token");
}

export function getItemStorage(key) {
  const item = localStorage.getItem(key);

  if (item) {
    return item;
  }
  throw new Error(
    `Couldn't find item at localStorage: ${key}`
  );
}


export async function getUserStorage(){

  const id = localStorage.getItem("id");
  const username = localStorage.getItem("username");
  const role = localStorage.getItem("role");
  const token = localStorage.getItem("token");
  const date = localStorage.getItem("date")

  if(id && username && role && token && date){

    const user = await new setUserStorage(id,username, role, token, date);

    return user;
  }
  throw new Error(
    `Couldn't find user at localStorage`
  );
}

export function setAuthTravelStorage(seconds, token) {
  const miliseconds = seconds * 900;
  const expiresAt = new Date(Date.now() + miliseconds);

  const expiresAtString = expiresAt.toISOString();

  localStorage.setItem("authExpiresInTravel", expiresAtString);
  localStorage.setItem("authTokenTravel", token)
}

export function getAuthTravelStorage(){

  const expiresIn = localStorage.getItem("authExpiresInTravel")
  const token = localStorage.getItem("authTokenTravel")

  if(expiresIn && token){
    const user =  new setUserAuthStorageTravel(token, expiresIn);
    return user;
  }else return null;

}

export function getClienteCPF(){
  return localStorage.getItem("cpf");
}


export function setClienteCodigo(codigo){
  localStorage.setItem("clienteCodigo", codigo);
}

export function getClienteCodigo(){
  return localStorage.getItem("clienteCodigo");
}


export function logoutClienteStorage() {
  setClienteCPF("")
  localStorage.removeItem("cpf");
  localStorage.removeItem("clienteCodigo");
}

export function getEnderecoEntrega(){
  return localStorage.getItem("enderecoEntrega");
}
export function setEnderecoEntrega(endereco){
  return localStorage.setItem("enderecoEntrega", endereco);
}

export function setCupom(cupom) {
  localStorage.setItem("cupom", cupom);
}

export function getCupom() {
  return localStorage.getItem("cupom");
}
