import React from 'react';
import './global.css';

import banner500x700 from '../../../assets/images/banner/global/banner-500X700.png';
import banner500x920 from '../../../assets/images/banner/global/banner-500X920.png';
import banner600x1400 from '../../../assets/images/banner/global/banner-600x1400.png';
import banner600x2500 from '../../../assets/images/banner/global/banner-600x2500.png';
import banner700x320 from '../../../assets/images/banner/global/banner-700x320.png';
import banner700x400 from '../../../assets/images/banner/global/banner-700x400.png';
import banner700x520 from '../../../assets/images/banner/global/banner-700x520.png';

export default function Global() {
  const handleRedirect = () => {
    window.open('https://om.ourominas.com/contaglobal', '_blank'); 
  }

  return (
    <main>
      <div className="bannerGlobal">
        <picture>
          <source media="(max-width: 519px)" srcSet={banner500x700} />
        
          <source media="(min-width: 520px) and (max-width: 699px)" srcSet={banner700x400} />

          <source media="(min-width: 560px) and (max-width: 909px)" srcSet={banner700x520} />
          
         
          
          <source media="(min-width: 910px) and (max-width: 1400px)" srcSet={banner600x1400} />
          
          <source media="(min-width: 1401px)" srcSet={banner600x2500} />
          
          <img src={banner700x320} alt="Banner Responsivo" className="bannerImg" />
        </picture>
        <button className="saibaMaisBtn" onClick={handleRedirect}>
        </button>
      </div>
    </main>
  );
}
