import React, { useEffect, useState } from 'react';
import './brindes.css';
import abridor from '../../../../assets/images/brindes/abridor.png';
import caderneta from '../../../../assets/images/brindes/caderneta.png';
import caneta from '../../../../assets/images/brindes/caneta.png';
import doleira from '../../../../assets/images/brindes/doleira.png';
import lapis from '../../../../assets/images/brindes/lapis.png';
import bone from '../../../../assets/images/brindes/bone.png';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRounded from '@mui/icons-material/ArrowForwardIosRounded';

const styleIcons = {
  width: '.8rem',
  justifyContent: 'center',
  marginRight: '5px',
};

export interface Brinde {
  item: string;
  valorMinimo: number;
  descricao?: string;
  image?: string;
}

interface BrindesComponentProps {
  valorCompra: number;
  onAddBrinde: (brinde: Brinde) => void;
  onRemoveItem: (item: string) => void;
  carrinho: string[];
  onToggleBrinde: (brinde: Brinde) => void;
}

const brindesDisponiveis: Brinde[] = [
  {
    item: 'Doleira',
    valorMinimo: 2000,
    descricao: 'Disponível para compras acima de 2000',
    image: doleira,
  },
  {
    item: 'Chaveiro/abridor',
    valorMinimo: 500,
    descricao: '2G de ouro',
    image: abridor,
  },
  {
    item: 'Bloco de Anotações',
    valorMinimo: 2000,
    descricao: '10G de ouro',
    image: caderneta,
  },
  { item: 'Caneta', valorMinimo: 100, descricao: '1G de ouro', image: caneta },
  { item: 'Lápis', valorMinimo: 100, descricao: '1G de ouro', image: lapis },
  { item: 'Boné', valorMinimo: 5000, descricao: '25G de ouro', image: bone },
];

const BrindesComponent: React.FC<BrindesComponentProps> = ({
  valorCompra,
  carrinho = [],
  onToggleBrinde,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [brindeSelecionado, setBrindeSelecionado] = useState<Brinde | null>(
    null
  );
  const [isBrindeAcima, setIsBrindeAcima] = useState(false);

  const itemsPerPage =
    window.innerWidth >= 900 ? 4 : window.innerWidth >= 680 ? 3 : 2;

  const scrollLeft = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - itemsPerPage, 0));
  };

  const scrollRight = () => {
    setCurrentIndex((prevIndex) =>
      Math.min(
        prevIndex + itemsPerPage,
        brindesDisponiveis.length - itemsPerPage
      )
    );
  };

  const handleBrindeClick = (brinde: Brinde) => {
    if (valorCompra >= brinde.valorMinimo && !carrinho.includes(brinde.item)) {
      onToggleBrinde(brinde);
      setBrindeSelecionado(brinde);
      setIsBrindeAcima(true);
    }
  };

  const handleRemoveBrinde = () => {
    if (brindeSelecionado) {
      setBrindeSelecionado(null); // Remove o brinde do estado local
      setIsBrindeAcima(false); // Atualiza o estado visual
      onToggleBrinde(null); // Notifica o pai que o brinde foi removido
    }
  };

  useEffect(() => {
    const brindeNoCarrinho = carrinho.find(
      (item) => item === brindeSelecionado?.item
    );
    if (brindeNoCarrinho) {
      setBrindeSelecionado(null);
      setIsBrindeAcima(false);
    }
  }, [carrinho, brindeSelecionado]);

  return (
    <div className="brindes">
      {brindeSelecionado ? (
        <>
          <hr className="divider" />
          <div
            className={`brinde-selecionado ${
              isBrindeAcima ? 'acima' : 'abaixo'
            }`}
          >
            <div>
              <div className="brinde-info">
                <img
                  src={brindeSelecionado.image}
                  alt={brindeSelecionado.item}
                  className="brinde-imagem"
                />
                <h4 className="brinde-titulo">{brindeSelecionado.item}</h4>
              </div>
              <button className="remover" onClick={handleRemoveBrinde}>
                <DeleteIcon style={styleIcons} />
                Remover
              </button>
            </div>
            <div className="brinde-detalhes">
              <div className="brinde-quantidade-valor">
                <p className="quantidade">Quantidade: 1</p>
                <p className="valor-brinde">Total:&nbsp; R$ 0,00</p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <h3>Escolha seu brinde Ourominas</h3>
          <div className="brindes-container">
            <ArrowBackIosNewRoundedIcon
              style={{ color: '#cba052', fontSize: 45 }}
              onClick={scrollLeft}
            />
            <div className="brindes-grid">
              {brindesDisponiveis
                .slice(currentIndex, currentIndex + itemsPerPage)
                .map((brinde, index) => {
                  const isAvailable =
                    valorCompra >= brinde.valorMinimo &&
                    !carrinho.includes(brinde.item);
                  const isSelected =
                    brindeSelecionado && brindeSelecionado.item === brinde.item;

                  return (
                    <div
                      key={index}
                      className={`brinde-item ${
                        !isAvailable ? 'unavailable' : ''
                      } ${isSelected ? 'selected' : ''}`}
                      onClick={() => isAvailable && handleBrindeClick(brinde)}
                    >
                      <div className="brinde-overlay">
                        <p>
                          {brinde.item} {!isAvailable && ''}
                        </p>
                      </div>
                      <img
                        src={brinde.image}
                        alt={brinde.item}
                        className="brinde-image"
                      />
                    </div>
                  );
                })}
            </div>
            <ArrowForwardIosRounded
              style={{ color: '#cba052', fontSize: 45 }}
              onClick={scrollRight}
            />
          </div>
        </>
      )}
      <hr className="divider" />
      <h3 className="interesse">
        Os produtos abaixo podem lhe interessar: (em breve)
      </h3>
    </div>
  );
};

export default BrindesComponent;
