import React from 'react';
import img from '../../../assets/vender-ouro.png';
import '../../pages.css';

import linha from '../../../assets/linha.png';
export default function VenderOuro() {
  return (
    <article className="art-s mb-5 p-text">
      <section className="row m-0 sectionInit">
        <div className="c-init">
          <div className="c-init__text col-6  d-flex flex-column sect mt-5">
            <p className="fw-bolder text-decoration-underline">
              VENDER MEU OURO
            </p>
            <p>
              Na Ourominas (OM) o cliente pode contar com uma equipe formada por
              profissionais com foco na análise e avaliação do ouro com
              segurança e a melhor valorização do mercado. 
            </p>
            <p>
              A OM efetua a compra de ouro em todos os teores, maciços, ocos ou
              ocados, barras (que podem ou não ser da OM), moedas, joias,
              relógios e fragmentos, ou seja, se você possui uma aliança,
              corrente, colar, pulseira ou qualquer objeto em ouro que queira se
              desfazer e vender com segurança e sem qualquer preocupação, a OM é
              a empresa mais qualificada.
            </p>
            <p>
              Além de um atendimento personalizado, a OM cobre qualquer oferta
              real da concorrência (anunciada e comprovada) e após a avaliação e
              negociação do ouro o pagamento e feito à vista.
            </p>
            <div className="div-line">
              <img src={linha} alt="linha de separação"></img>
            </div>
          </div>
          <div className="col-6 c-init__image">
            <img src={img} className="image" alt="grupo-om"></img>
          </div>
        </div>

        <p className="mt-4">
          <b>Entenda como é calculado o ouro contido na peça:</b>
        </p>
        <p>
          O valor pago no ouro é correspondente ao peso e teor do metal, ou
          seja, o ouro de melhor qualidade, tem liga 750 (ouro 18k) ou superior,
          750 corresponde a uma joia com 75% ouro e 25% liga (prata e cobre),
          quanto maior for esse número, maior é a porcentagem de ouro na
          composição da peça, logo seu valor será superior.
        </p>

        <p>
          Muito cuidado com os compradores desconhecidos, evite correr riscos de
          ter o metal avaliado por um valor inferior e fazer um péssimo negócio.
          Vendendo para a OM, o cliente pode ter a certeza de que estará fazendo
          um excelente negócio, com a garantia de sempre receber o valor correto
          pelo metal. 
        </p>

        <section className="mt-4 sect">
          <p className=" mt-5 my-3  fw-bolder">PERGUNTAS FREQUENTES</p>
          <details className="mb-2 pb-2 border-bottom">
            <summary>O QUE A OUROMINAS COMPRA? </summary>
            <ul className="mt-2">
              <li>Barra de ouro;</li>
              <li>Ouro Laminado;</li>
              <li>Joias (mesmo quebradas ou partidas);</li>
              <li>Moedas de Ouro</li>
            </ul>
          </details>
          <details className="mb-2 pb-2 border-bottom">
            <summary>POR QUE VENDER OURO PARA A OURMINAS? </summary>
            <p className="mt-2">
              Mais de 40 anos de experiência no mercado, garantindo
              credibilidade e segurança. Transparência total em todas as etapas
              da avaliação e negociação.
            </p>
            <p>
              Profissionais qualificados realizam a análise do seu ouro em
              poucos minutos. Tranquilidade e segurança para quem deseja vender
              sem correr riscos.
            </p>
          </details>

          <details className="mb-2 pb-2 border-bottom">
            <summary>
              POR QUE NÃO COMPRAMOS TODAS AS JOIAS EM OURO BRANCO?{' '}
            </summary>
            <p className="mt-2">
              Se você tem uma joia em ouro branco e pretende vender, ou já veio
              até nós com interesse de vendê-la, saiba que não são todas as
              joias de ouro branco que compramos.
            </p>
            <p>
              A nossa avaliação para o ouro branco visa uma liga (uma mistura)
              específica, composta de ouro amarelo e paládio.
            </p>
            <p>
              Muitas das joalherias pelo Brasil utilizam liga italiana, mais
              focada na cor, do que na qualidade do material. Saiba que a liga
              italiana possui prata e outros metais de baixo valor em sua
              composição, e para nós não tem nenhum valor comercial.
            </p>
          </details>
          <details className="mb-2 pb-2 border-bottom">
            <summary>COMO VENDER OURO USADO PARA A OUROMINAS?</summary>
            <p className="mt-2">
              Vender ouro ou joias para a Ourominas é um processo rápido, seguro
              e transparente.
            </p>
            <p>
              <strong>Veja como funciona:</strong>
            </p>
            <p>Avaliação do Material</p>
            <p>
              Traga suas barras de ouro, joias (mesmo quebradas ou danificadas),
              moedas de ouro ou ouro laminado para uma de nossas unidades.
            </p>
            <p>
              Nossos especialistas farão uma análise detalhada do material em
              poucos minutos.
            </p>
            <p>Cotação Justa e Transparente</p>
            <p>
              A oferta é baseada na cotação atual do ouro no mercado, garantindo
              um preço justo.
            </p>
            <p>
              Você recebe todas as informações com total transparência antes de
              decidir pela venda.
            </p>
            <p>Pagamento Rápido e Seguro</p>
            <p>
              Após a aprovação da cotação, o pagamento é realizado de imediato
              de forma ágil e segura.{' '}
              <strong>Segurança e Credibilidade:</strong> Com mais de 40 anos de
              experiência, a Ourominas é referência no mercado de compra e venda
              de ouro.
            </p>
          </details>

          <details className="mb-2 pb-2 border-bottom">
            <summary> CADASTRO.</summary>
            <ul>
              <p className="mt-2">
                <strong>Cadastre-se na OM </strong>
              </p>
              <li>
                Escolha entre o<strong> Cadastro Simplificado</strong> (para
                operações de pequeno valor) ou o{' '}
                <strong>Cadastro Completo</strong> (para valores maiores e
                operações recorrentes).
              </li>
              <p className="mt-2">
                <strong>Entre em Contato </strong>
              </p>
              <li>
                Realize suas compras por meio dos canais oficiais de atendimento
                da OM presencial ou online.
              </li>
              <p className="mt-2">
                <strong>Confirme a Operação </strong>
              </p>
              <li>
                Após a negociação, a operação deve ser liquidada dentro do prazo
                combinado. Caso contrário, será cancelada automaticamente.
              </li>
            </ul>
          </details>

          <details className="mb-2 pb-2 border-bottom">
            <summary>HORÁRIO DE NEGOCIAÇÃO. </summary>
            <p className="mt-2">
              Nosso horário de negociação: Segunda à Sexta, das 8:30 às 18:00h
              (Horário de Brasília). Temos horários diferenciados em alguns
              pontos de atendimento. Consulte quais lojas possuem avaliação do
              ouro na hora.
            </p>
          </details>
        </section>
      </section>
    </article>
  );
}
