import React from 'react';
import './CambioComercial.css';
import banner700x400 from '../../../assets/images/banner/cambioComercial/MOBILE/700X400.png';
import banner700x520 from '../../../assets/images/banner/cambioComercial/MOBILE/700X520.png';
import banner600x1400 from '../../../assets/images/banner/cambioComercial/DESKTOP/1400X600.png';
import banner600x2500 from '../../../assets/images/banner/cambioComercial/DESKTOP/2500x600.png';

export default function CambioComercial() {
  const handleRedirect = () => {
    window.open('https://om.ourominas.com/cambiocomercial', '_blank');
  };

  return (
    <main>
      <div className="bannerGlobal">
        <picture>
          <source
            media="(min-width: 910px) and (max-width: 1400px)"
            srcSet={banner600x1400}
          />

          <source media="(min-width: 1401px)" srcSet={banner600x2500} />

          <img
            src={banner700x520}
            alt="Banner Responsivo"
            className="bannerImg"
          />
        </picture>
        <button className="saibaMaisComercialBtn" onClick={handleRedirect}></button>
      </div>
    </main>
  );
}
