import styled from 'styled-components';
import Modal from '@mui/material/Modal';
import { Box, IconButton } from '@mui/material';
import React from 'react';
import embalagem from '../../../assets/images/modules/embalagem-seguranca.png';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

const styleIcons = {
  width: '.8rem',
  justifyContent: 'center',
  marginRight: '5px',
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 0,
  borderRadius: '8px',

  '@media (min-width: 600px)': {
    width: '700px',
  },
};

const CustomCheckbox = styled(Checkbox)`
  padding: 0;
  margin-right: 5px;
  margin-top: -3px;
`;

export default function EmbalagemProtetora() {
  const [openModal, setOpenModal] = React.useState(false);

  const handleOpen = (e, item) => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };
  return (
    <>
      <FormGroup className="flex flex-row w-full">
        <Label>
          <AddIcon style={styleIcons} />
          <p>
            Adicionar{' '}
            <Button onClick={handleOpen}>embalagem protetora gratuita</Button>
          </p>
        </Label>
      </FormGroup>

      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CloseButton>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </CloseButton>
          <ContainerImage>
            <img
              src={embalagem}
              alt="imagem da embalagem protetora frente e verso"
            />
          </ContainerImage>
        </Box>
      </Modal>
    </>
  );
}

const ContainerImage = styled.div`
  display: flex;
  justify-content: center;
  > img {
    width: 90%;
    margin: 0 auto;
  }
`;

const Label = styled.label`
  display: flex;
  align-items: flex-start;

  svg {
    width: 0.5em;
  }

  p {
    color: #758080;
    font-size: 14px;
    font-weight: 400;
  }
`;

const Button = styled.button`
  color: #758080;
  text-decoration: underline;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;
