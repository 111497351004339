import React, { useState } from 'react';
import '../../pages.css';
import img from '../../../assets/newImages/projetos-sociais.png';
import br1 from '../../../assets/projeto-brasileirinhos.png';
import br2 from '../../../assets/projeto-brasileirinhos2.png';
import br3 from '../../../assets/projeto-brasileirinhos3.png';
import crianca from '../../../assets/projetoCriancas.png';
import crianca2 from '../../../assets/projetoCriancas2.png';
import crianca3 from '../../../assets/projetoCriancas3.png';
import idoso from '../../../assets/projeto-idoso.png';
import idoso2 from '../../../assets/projeto-idoso2.png';
import idoso3 from '../../../assets/projeto-idoso3.png';
import linha from '../../../assets/linha.png';
import inclusao from '../../../assets/projeto-inclusao.png';
import projetoSocial from '../../../assets/projetoSocial.png';
import projetoSocial2 from '../../../assets/projetoSocial2.png';
import projetoSocial3 from '../../../assets/projetoSocial3.png';
import projetoSocial4 from '../../../assets/projetoSocial4.png';
import projetoSocial5 from '../../../assets/projetoSocial5.png';
import projetoSocial6 from '../../../assets/projetoSocial6.png';
import projetoSocial7 from '../../../assets/projetoSocial7.png';
import cultura from '../../../assets/images/projetosSociais/cultura.png';
import cultura2 from '../../../assets/images/projetosSociais/cultura2.png';
import cultura3 from '../../../assets/images/projetosSociais/cultura3.png';
import cultura4 from '../../../assets/images/projetosSociais/cultura4.png';
import cultura5 from '../../../assets/images/projetosSociais/cultura5.png';
import taekwondo from '../../../assets/images/projetosSociais/taekwondo.png';
import taekwondo2 from '../../../assets/images/projetosSociais/taekwondo2.png';
import fome from '../../../assets/images/projetosSociais/fome.png';
import fome2 from '../../../assets/images/projetosSociais/fome2.png';
import brasileirinhos2023 from '../../../assets/images/projetosSociais/brasileirinhos-2023.jpeg';
import brasileirinhos23 from '../../../assets/images/projetosSociais/RelatoriosProjetoSocial/BRASILEIRINHOS IV - LS Nogueira.pdf';
import brasileirinhos24 from '../../../assets/images/projetosSociais/brasileirinhos-24.jpg';
import fada from '../../../assets/docs/fundacao-apoio-desenvolvimento-autista.pdf';
import onibusRosa from '../../../assets/images/projetosSociais/onibus-rosa.webp';
import cedro from '../../../assets/docs/Termo-de-Recebimento-Cedro.PDF';
import hospitalAmor from '../../../assets/images/projetosSociais/RelatoriosProjetoSocial/Relatório Anual Hospital de Amor_2022.pdf';
import hospitalAmor2 from '../../../assets/images/projetosSociais/RelatoriosProjetoSocial/Relatório HOSPITAL DE AMOR 2023.pdf';
import hospitalAmor3 from '../../../assets/images/projetosSociais/RelatoriosProjetoSocial/Relatório de atendimentos do Hospital de Amor (2023) - PROJETO AMPARO AO IDOSO (1).pdf';
import amparoIdoso from '../../../assets/images/projetosSociais/RelatoriosProjetoSocial/Relatório de atendimentos do Hospital de Amor (2023) - PROJETO AMPARO AO IDOSO.pdf';
import antendimentoOncologico from '../../../assets/images/projetosSociais/RelatoriosProjetoSocial/LS_ Vida e Saúde (Santa Rosa-RS) - PRONON.pdf';
import reabilitapae from '../../../assets/images/projetosSociais/RelatoriosProjetoSocial/LS_ReabilitAPAE.pdf';
import relatorioProjetoInclusaoOlga from '../../../assets/images/projetosSociais/RelatoriosProjetoSocial/RELATÓRIO - X INCLUSÃO A TODA PROVA.pdf';

import {
  CarrouselBootstrap,
  CarrouselBootstrap7,
  CarrouselBootstrapFinal,
} from '../../../components/carousel/carousel';
import styled from 'styled-components';

export default function ProjetosSociais() {
  const [exibirBR, setExibirBR] = useState(false);
  const [exibirBR2023, setExibirBR2023] = useState(false);
  const [exibirBR2024, setExibirBR2024] = useState(false);
  const [exibirCrianca, setExibirCrianca] = useState(false);
  const [exibirIdoso, setExibirIdoso] = useState(false);
  const [exibirVozes, setExibirVozes] = useState(false);
  const [exibirInclusao, setExibirInclusao] = useState(false);
  const [exibirSocial, setExibirSocial] = useState(false);
  const [exibirTaekowndo, setExibirTaekondo] = useState(false);
  const [exibirFome, setExibirFome] = useState(false);
  const [exibirOnibus, setExibirOnibus] = useState(false);

  let imagesBR = [
    {
      img1: br1,
      img2: br2,
      img3: br3,
    },
  ];
  let imagesCrianca = [
    {
      img1: crianca,
      img2: crianca2,
      img3: crianca3,
    },
  ];
  let imagesFome = [
    {
      img: fome,
    },
    { img: fome2 },
  ];
  let imagesIdoso = [
    {
      img1: idoso,
      img2: idoso2,
      img3: idoso3,
    },
  ];

  let imagesCultura = [
    { img: cultura },
    { img: cultura2 },
    { img: cultura3 },
    { img: cultura4 },
    { img: cultura5 },
  ];

  let imagesProjetoSocial = [
    {
      img1: projetoSocial,
      img2: projetoSocial2,
      img3: projetoSocial3,
      img4: projetoSocial4,
      img5: projetoSocial5,
      img6: projetoSocial6,
      img7: projetoSocial7,
    },
  ];

  let imagesTaekwondo = [{ img: taekwondo }, { img: taekwondo2 }];

  return (
    <article className="art-s mb-5 p-text" id="credibilidade">
      <section className="row m-0 sectionInit">
        <div className="c-init ">
          <div className="c-init__text col-6  d-flex flex-column sect">
            <p className="fw-bolder text-decoration-underline">
              PROJETOS SOCIAIS
            </p>
            <p>
              A Ourominas (OM) através das Leis de Incentivos Fiscais destina
              uma parte dos impostos para ações culturais, sociais, esportivas e
              de saúde.
            </p>
            <p>Conheça abaixo os projetos que a OM apoia.</p>
            <div className="div-line">
              <img src={linha} alt="linha de separação"></img>
            </div>
          </div>
          <div className="c-init__image col-6 div-image ">
            <img src={img} className="image" alt="grupo-om"></img>
          </div>
        </div>

        <hr className="mt-5"></hr>
        <h5 className="fw-bolder mt-4">Projetos de Saúde</h5>
        <p>Pronon (Programa Nacional de Apoio à Atenção Oncológica)</p>
        <p>
          Voltado ao atendimento gratuito e de alta qualidade em várias frentes
          da medicina no país. Através do Pronon é possível viabilizar
          pesquisas, novos equipamentos, custeio de hospitais, novos
          procedimentos médicos e o tratamento oncológico. 
        </p>

        <button
          className="text-start btn text-decoration-underline"
          onClick={() => setExibirSocial(!exibirSocial)}
        >
          Projeto de Saúde: Hospital Ana Nery - 2021
        </button>
        {exibirSocial && (
          <>
            <p className="w-100 text-center">
              <button
                className="btn btn-danger"
                onClick={() => setExibirSocial(false)}
              >
                X
              </button>
            </p>

            <CarrouselBootstrap7
              props={imagesProjetoSocial}
            ></CarrouselBootstrap7>
          </>
        )}
        <BoxLinks>
          <a target="_blank" rel="noreferrer" href={hospitalAmor}>
            Projeto Cuidar: Hospital do Amor - FUMCAD - 2022
          </a>
        </BoxLinks>
        <BoxLinks>
          <a target="_blank" rel="noreferrer" href={hospitalAmor2}>
            Projeto Cuidar: Hospital do Amor - FUMCAD - 2023 - 1° Semestre
          </a>
        </BoxLinks>
        <BoxLinks>
          <a target="_blank" rel="noreferrer" href={hospitalAmor3}>
            Projeto Cuidar: Hospital do Amor - FUMCAD - 2023 - 2° Semestre
          </a>
        </BoxLinks>
        <BoxLinks>
          <a target="_blank" rel="noreferrer" href={antendimentoOncologico}>
            Projeto Atendimento Oncológico - PRONON - 2023
          </a>
        </BoxLinks>

        <BoxLinks>
          <a target="_blank" rel="noreferrer" href={reabilitapae}>
            Projeto Reabilitapae - PRONAS - 2023
          </a>
        </BoxLinks>

        <hr className="mt-3"></hr>
        <h5 className="fw-bolder mt-4">Projetos de Inclusão</h5>
        <p>
          Pronas (Programa Nacional de Apoio à Atenção da Saúde da Pessoa com
          Deficiência)
        </p>
        <p>
          Também chamada de Lei da Mobilidade Física, é a primeira lei criada
          para construir e manter projetos que possibilitam a assistência,
          prevenção de doenças, tratamentos e a recuperação de pessoas com
          câncer ou com necessidades especiais.
        </p>

        <button
          className="text-start btn text-decoration-underline"
          onClick={() => setExibirInclusao(!exibirInclusao)}
        >
          Projeto Inclusão: APADV Paralímpico - 2021
        </button>
        <BoxLinks>
          <a
            target="_blank"
            rel="noreferrer"
            href={relatorioProjetoInclusaoOlga}
          >
            Projeto Inclusão: Olga Kos - 2024
          </a>
        </BoxLinks>

        {exibirInclusao && (
          <>
            <p className="w-100 text-center">
              <button
                className="btn btn-danger"
                onClick={() => setExibirInclusao(false)}
              >
                X
              </button>
            </p>
            <Image src={inclusao} />
          </>
        )}

        <hr className="mt-3"></hr>
        <h5 className="fw-bolder mt-4">Projetos de Idoso</h5>
        <p>Fundo Municipal do Idoso</p>
        <p>
          Tem o objetivo de assegurar, através de projetos sociais, os direitos
          de pessoas carentes com 60 anos ou mais, garantindo saúde,
          assistência, inclusão social e lazer, proporcionando qualidade de vida
          aos idosos, ou seja, criar condições para promover a autonomia e
          bem-estar.
        </p>
        <p>Amparo ao Idoso</p>
        <p>Cotas de ingresso</p>
        <button
          className="text-start btn text-decoration-underline"
          onClick={() => {
            setExibirOnibus(false);
            setExibirIdoso(!exibirIdoso);
          }}
        >
          Amparo ao Idoso – HCB - 2022
        </button>
        <BoxLinks>
          <a target="_blank" rel="noreferrer" href={amparoIdoso}>
            Amparo ao Idoso - 2023
          </a>
        </BoxLinks>
        <button
          className="text-start btn text-decoration-underline"
          onClick={() => {
            setExibirIdoso(false);
            setExibirOnibus(!exibirOnibus);
          }}
        >
          Ônibus Rosa, envelhecendo saudável e saúde da mulher - 2022
        </button>
        {exibirIdoso && (
          <>
            <p className="w-100 text-center">
              <button
                className="btn btn-danger"
                onClick={() => setExibirIdoso(false)}
              >
                X
              </button>
            </p>
            <CarrouselBootstrap props={imagesIdoso}></CarrouselBootstrap>
          </>
        )}
        {exibirOnibus && (
          <>
            <p className="w-100 text-center">
              <button
                className="btn btn-danger"
                onClick={() => setExibirOnibus(false)}
              >
                X
              </button>
            </p>
            <Image src={onibusRosa} />
          </>
        )}

        <hr className="mt-3"></hr>
        <h5 className="fw-bolder mt-4">Projetos de Criança</h5>
        <p>Fundo Municipal dos Direitos da Criança e do Adolescente</p>
        <p>
          É um instrumento essencial para a população infanto-juvenil. Projetos
          destinados para esse público são capazes de dar um lar, formar,
          educar, dar saúde e lazer desde os primeiros anos de vida.
        </p>
        <button
          className="text-start btn text-decoration-underline"
          onClick={() => {
            setExibirCrianca(false);
            setExibirFome(!exibirFome);
          }}
        >
          Fome Não Espera - 2021
        </button>
        <button
          className="text-start btn text-decoration-underline"
          onClick={() => {
            setExibirFome(false);
            setExibirCrianca(!exibirCrianca);
          }}
        >
          Cuidar HCB - Hospital de Amor - 2022
        </button>
        {exibirCrianca && (
          <>
            <p className="w-100 text-center">
              <button
                className="btn btn-danger"
                onClick={() => setExibirCrianca(false)}
              >
                X
              </button>
            </p>
            <CarrouselBootstrap props={imagesCrianca}></CarrouselBootstrap>
          </>
        )}
        {exibirFome && (
          <>
            <p className="w-100 text-center">
              <button
                className="btn btn-danger"
                onClick={() => setExibirFome(false)}
              >
                X
              </button>
            </p>
            <CarrouselBootstrapFinal
              props={imagesFome}
            ></CarrouselBootstrapFinal>
          </>
        )}

        <hr className="mt-3"></hr>
        <h5 className="fw-bolder mt-4">Projetos de Esporte</h5>
        <p>
          Desde o incentivo à cultura da prática de esporte, como reforço na
          saúde até a inserção a nível profissional, dando oportunidade para
          futuros atletas, inclusive olímpicos.
        </p>
        <button
          className="text-start btn text-decoration-underline"
          onClick={() => {
            setExibirBR(false);
            setExibirBR2023(false);
            setExibirTaekondo(!exibirTaekowndo);
          }}
        >
          Clínica de Taekwondo nas escolas públicas - 2020, 2021
        </button>
        <button
          className="text-start btn text-decoration-underline"
          onClick={() => {
            setExibirTaekondo(false);
            setExibirBR2023(false);
            setExibirBR(!exibirBR);
          }}
        >
          Projeto Brasileirinhos - 2022
        </button>
        <BoxLinks>
          <a target="_blank" rel="noreferrer" href={brasileirinhos23}>
            Projeto Brasileirinhos - 2023 - 1° Semestre
          </a>
        </BoxLinks>
        <button
          className="text-start btn text-decoration-underline"
          onClick={() => {
            setExibirTaekondo(false);
            setExibirBR(false);
            setExibirBR2023(!exibirBR2023);
          }}
        >
          Projeto Brasileirinhos - 2023 - 2º Semestre
        </button>
        <button
          className="text-start btn text-decoration-underline"
          onClick={() => {
            setExibirTaekondo(false);
            setExibirBR(false);
            setExibirBR2024(!exibirBR2024);
          }}
        >
          Projeto Brasileirinhos - 2024
        </button>
        {exibirBR && (
          <>
            <p className="w-100 text-center">
              <button
                className="btn btn-danger"
                onClick={() => setExibirBR(false)}
              >
                X
              </button>
            </p>
            <CarrouselBootstrap props={imagesBR}></CarrouselBootstrap>
          </>
        )}
        {exibirBR2023 && (
          <>
            <p className="w-100 text-center">
              <button
                className="btn btn-danger"
                onClick={() => setExibirBR2023(false)}
              >
                X
              </button>
            </p>

            <Image src={brasileirinhos2023} />
          </>
        )}
        {exibirBR2024 && (
          <>
            <p className="w-100 text-center">
              <button
                className="btn btn-danger"
                onClick={() => setExibirBR2024(false)}
              >
                X
              </button>
            </p>

            <Image src={brasileirinhos24} />
          </>
        )}
        {exibirTaekowndo && (
          <>
            <p className="w-100 text-center">
              <button
                className="btn btn-danger"
                onClick={() => setExibirTaekondo(false)}
              >
                X
              </button>
            </p>
            <CarrouselBootstrapFinal props={imagesTaekwondo} />
          </>
        )}
        <BoxLinks>
          <a
            target="_blank"
            rel="noreferrer"
            href={
              'https://www.ticketsports.com.br/e/x-inclusao-a-toda-prova-corrida-e-caminhada-em-homenagem-ao-dia-internacional-da-pessoa-com-deficiencia-sao-paulo-i-dezembro-2024-38426'
            }
          >
            KARATÊ - Vitória da Inclusão
          </a>
        </BoxLinks>
        <hr className="mt-3"></hr>
        <h5 className="fw-bolder mt-4">Projeto de Cultura</h5>

        <p>
          PRONAC ROUANET - Com o objetivo de ampliar o acesso à cultura, o
          Pronac (Programa Nacional de Apoio à Cultura) - instituído pela Lei n°
          8.313/91 e regulamentado pelo Decreto nº 5.761/06 -, apoia, valoriza e
          difunde as manifestações culturais brasileiras. Ele estimula a
          produção cultural, o que o torna um gerador de renda, emprego e
          desenvolvimento.
        </p>
        <p>
          CULTURA ROUANET - Cota extra de 40 ingressos a serem utilizados no
          musical 80 – A DÉCADA DO VALE TUDO, que ficará em cartaz no Teatro
          Claro – São Paulo, até setembro de 2023.
        </p>
        <button
          className="text-start btn text-decoration-underline"
          onClick={() => {
            setExibirVozes(!exibirVozes);
          }}
        >
          Projeto Elas Brilham - Doc. Musical - 2022
        </button>

        {exibirVozes && (
          <>
            <p className="w-100 text-center">
              <button
                className="btn btn-danger"
                onClick={() => {
                  setExibirVozes(false);
                }}
              >
                X
              </button>
            </p>
            <CarrouselBootstrapFinal props={imagesCultura} />
          </>
        )}

        <hr className="mt-3"></hr>
        <h5 className="fw-bolder mt-4">
          Projeto de TEA (Transtorno do Espectro Autista)
        </h5>
        <p>
          Estimular o convívio social, desenvolvimento de potencialidades e
          ampliação de qualidade de vida para as pessoas com TEA (transtorno do
          espectro autista) e seus familiares.
        </p>
        <BoxLinks>
          <a target="_blank" rel="noreferrer" href={fada}>
            FADA 2024 - Fundação de Apoio e Desenvolvimento do Autista
          </a>
          <a
            href="https://www.fada.org.br/nossa-historia/"
            target="_blank"
            rel="noreferrer"
          >
            Clique aqui para acessar o site.
          </a>
        </BoxLinks>

        <hr className="mt-3"></hr>
        <h5 className="fw-bolder mt-4">Proteção à Infância</h5>
        <p>Associação Cedro do Líbano</p>
        <p>
          O Cedro do Líbano é uma organização que atua na proteção da infância e
          na promoção dos direitos das crianças e adolescentes. Foca em ações de
          acolhimento, educação e inclusão social, visando garantir um
          desenvolvimento seguro e saudável para os jovens em situação de
          vulnerabilidade.
        </p>
        <BoxLinks>
          <a target="_blank" rel="noreferrer" href={cedro}>
            Projeto de Proteção à Infância: Associação Cedro do Líbano - 2024
          </a>
        </BoxLinks>
      </section>
    </article>
  );
}

const Image = styled.img`
  @media (min-width: 760px) {
    width: 800px;
    margin: 0 auto;
  }
`;

const BoxLinks = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 2px;

  a {
    font-size: 16px;
    color: #212529;
    margin-right: 10px;
    font-weight: normal;
    font-family: Roboto;
  }
`;
