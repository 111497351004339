import React from "react";
import "../../pages.css";
import img from "../../../assets/newImages/demonstracoes-contabeis.png";
import demonstracoes201901 from "../../../assets/docs/DEMONSTRACOES_ junho_2019.pdf";
import demonstracoes201902 from "../../../assets/docs/DEMONSTRACOES_ Dezembro_2019.pdf";
import demonstracoes202001 from "../../../assets/docs/DEMONSTRACOES_ junho_2020.pdf";
import demonstracoes202002 from "../../../assets/docs/DEMONSTRACOES_ Dezembro_2020.pdf";
import demonstracoes202101 from "../../../assets/docs/DEMONSTRACOES_ junho_2021.pdf";
import demonstracoes202102 from "../../../assets/docs/DEMONSTRACOES_ Dezembro_2021.pdf";
import demonstracoes202201 from "../../../assets/docs/DEMONSTRACOES_ junho_2022.pdf";
import demonstracoes202202 from "../../../assets/docs/demonstracoes-dezembro-2022.pdf";
import demonstracoes202301 from "../../../assets/docs/demonstracoes-junho-2023.pdf";
import demonstracoes202302 from "../../../assets/docs/demonstracoes-dezembro-2023.pdf";
import demonstracoes202401 from '../../../assets/docs/demonstracoes-junho-2024.pdf';

import linha from "../../../assets/linha.png";

export default function DemonstracoesContabeis() {
  return (
    <article className="art-s mb-5 p-text">
      <section className="row m-0 sectionInit">
        <div className="c-init">
          <div className="c-init__text col-6  d-flex flex-column sect mt-5">
            <p className="fw-bolder text-decoration-underline">
              DEMONSTRAÇÕES CONTÁBEIS
            </p>
            <p>
              As demonstrações contábeis são relatórios extraídos da
              contabilidade após o registro de todos os documentos que fizeram
              parte do sistema contábil da empresa em um determinado período e
              que devem ser obrigatoriamente divulgadas, anualmente, segundo a
              lei 6404/76.
            </p>
            <p>
              Essas demonstrações servirão para expressar a situação patrimonial
              da empresa, auxiliando assim os diversos usuários no processo de
              tomada de decisão.
            </p>
            <p>
            <section className="mt-4">
              <details className="mb-2 pb-2 border-bottom">
                <summary>Demonstrações Contábeis 2019</summary>
                <ul>
                  <li>
                    {" "}
                    <a
                      className="text-decoratio-none"
                      href={demonstracoes201901}
                      rel="noopene noreferrer"
                      target="_blank"
                    >
                      Primeiro Semestre
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-decoratio-none"
                      href={demonstracoes201902}
                      rel="noopene noreferrer"
                      target="_blank"
                    >
                      Segundo Semestre
                    </a>
                  </li>
                </ul>
              </details>

              <details className="mb-2 pb-2 border-bottom">
                <summary>Demonstrações Contábeis 2020</summary>
                <ul>
                  <li>
                    {" "}
                    <a
                      className="text-decoratio-none"
                      href={demonstracoes202001}
                      rel="noopene noreferrer"
                      target="_blank"
                    >
                      Primeiro Semestre
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-decoratio-none"
                      href={demonstracoes202002}
                      rel="noopene noreferrer"
                      target="_blank"
                    >
                      Segundo Semestre
                    </a>
                  </li>
                </ul>
              </details>

              <details className="mb-2 pb-2 border-bottom">
                <summary>Demonstrações Contábeis 2021</summary>
                <ul>
                  <li>
                    {" "}
                    <a
                      className="text-decoratio-none"
                      href={demonstracoes202101}
                      rel="noopene noreferrer"
                      target="_blank"
                    >
                      Primeiro Semestre
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-decoratio-none"
                      href={demonstracoes202102}
                      rel="noopene noreferrer"
                      target="_blank"
                    >
                      Segundo Semestre
                    </a>
                  </li>
                </ul>
              </details>

              <details className="mb-2 pb-2 border-bottom">
                <summary>Demonstrações Contábeis 2022</summary>
                <ul>
                  <li>
                    {" "}
                    <a
                      className="text-decoratio-none"
                      href={demonstracoes202201}
                      rel="noopene noreferrer"
                      target="_blank"
                    >
                      Primeiro Semestre
                    </a>
                  </li>
                  <li>
                    {" "}
                    <a
                      className="text-decoratio-none"
                      href={demonstracoes202202}
                      rel="noopene noreferrer"
                      target="_blank"
                    >
                      Segundo Semestre
                    </a>
                  </li>
                </ul>
              </details>

              <details className="mb-2 pb-2 border-bottom">
                <summary>Demonstrações Contábeis 2023</summary>
                <ul>
                  <li>
                    {" "}
                    <a
                      className="text-decoratio-none"
                      href={demonstracoes202301}
                      rel="noopene noreferrer"
                      target="_blank"
                    >
                      Primeiro Semestre
                    </a>
                  </li>
                  <li>
                    {" "}
                    <a
                      className="text-decoratio-none"
                      href={demonstracoes202302}
                      rel="noopene noreferrer"
                      target="_blank"
                    >
                      Segundo Semestre
                    </a>
                  </li>
                </ul>
              </details>

              <details className="mb-2 pb-2 border-bottom">
                <summary>Demonstrações Contábeis 2024</summary>
                <ul>
                  <li>
                    {" "}
                    <a
                      className="text-decoratio-none"
                      href={demonstracoes202401}
                      rel="noopene noreferrer"
                      target="_blank"
                    >
                      Primeiro Semestre
                    </a>
                  </li>
                  {/* <li>
                    {" "}
                    <a
                      className="text-decoratio-none"
                      href={demonstracoes202302}
                      rel="noopene noreferrer"
                      target="_blank"
                    >
                      Segundo Semestre
                    </a>
                  </li> */}
                </ul>
              </details>
            </section>
            </p>
            <div className="div-line">
              <img src={linha} alt="linha de separação"></img>
            </div>
          </div>
          <div className="col-6 c-init__image">
            <img src={img} className="image" alt="grupo-om"></img>
          </div>
        </div>
        <hr className="mt-5"></hr>
        <h5 className="fw-bolder mt-4">Balanço patrimonial – BP</h5>
        <p>
          É uma demonstração contábil que tem por objetivo mostrar a situação
          financeira e patrimonial de uma entidade numa determinada data.
          Representando, portanto, uma posição estática da mesma. O Balanço
          apresenta os Ativos (bens e direitos) e Passivos (obrigações) e o
          Patrimônio Líquido, que é resultante da diferença entre o total de
          ativos e passivos e onde se concentra o capital próprio da
          organização. Tanto os ativos quanto os passivos são demonstrados em
          função do seu prazo de vencimento, segmentados em Circulantes (para os
          vencimentos de curto prazo) e Não Circulantes (para os vencimentos de
          longo prazo).
        </p>

        <hr className="mt-5"></hr>
        <h5 className="fw-bolder mt-4">Demonstração do resultado – DRE</h5>
        <p>
          Destina-se a evidenciar a formação de resultado líquido do exercício,
          diante do confronto das receitas, custos e despesas apuradas segundo o
          regime de competência, a DR, antes chamada de DRE, oferece uma síntese
          econômica dos resultados operacionais de uma empresa em certo período.
          Embora sejam elaboradas anualmente para fins de divulgação, em geral
          são feitas mensalmente pela administração e trimestralmente para fins
          fiscais. As Demonstrações de Resultados, podem ser utilizadas como
          indicadores de auxílio a decisões financeiras.
        </p>

        <hr className="mt-5"></hr>
        <h5 className="fw-bolder mt-4">
          Demonstrações de mutações do patrimônio líquido (DMPL) 
        </h5>
        <p>
          No Brasil, a Lei das Sociedades por Ações aceita tanto a Demonstração
          das Mutações do Patrimônio Líquido, quanto a de Lucros ou Prejuízos
          Acumulados, sendo que a primeira é a mais completa, contendo,
          inclusive, uma coluna com os dados da segunda. A segunda, sendo
          obrigatória, pode ser substituída pela primeira.
        </p>
        <p>
          Há que se destacar que, após a edição da Lei 11.638/07[1], que alterou
          parcialmente a Lei 6.404/76, retificada e ratificada pela MP 449/09
          (transformada na Lei 11.941/09[2]), para as empresas S/A não existe
          mais a rubrica de Lucros Acumulados. Assim, na DMPL, constará a coluna
          intitulada Prejuízos Acumulados, onde serão registradas todas as
          movimentações que envolver o resultado do exercício em análise.
        </p>
        <p>
          Ela evidencia a mutação do Patrimônio Líquido em termos globais (novas
          integralizações de capital, resultado do exercício, ajustes de
          exercícios anteriores, dividendos, reavaliações, etc.) e em termos de
          mutações internas (no caso de prejuízo incorporações de reservas ao
          capital, no caso de lucro transferências de lucros acumulados para
          reservas, entre outras).
        </p>

        <hr className="mt-5"></hr>
        <h5 className="fw-bolder mt-4">
          Demonstração dos fluxos de caixa – DFC
        </h5>
        <p>
          Visa mostrar como ocorreram as movimentações das disponibilidades e o
          Fluxo de Caixa em um dado período de tempo; para auxiliar os usuários
          na avaliação da capacidade da entidade de gerar fluxos de caixa
          líquidos positivos. Vem substituindo em alguns países a Demonstração
          das Origens e Aplicações de Recursos e é fundamentada pela Lei n°
          11.638/07 no Brasil. O nome demonstração dos fluxos de caixa já não é
          mais tão adequado, sendo mais correto fluxos de disponíveis, porém o
          nome é usado pela tradição do mesmo.
        </p>

        <hr className="mt-5"></hr>
        <h5 className="fw-bolder mt-4">Relatório da administração</h5>
        <p>
          Não faz parte das demonstrações contábeis propriamente ditas, mas a
          lei exige a apresentação do relatório, que deve evidenciar os
          principais fatos administrativos e financeiros ocorridos no período
          (exercício), os investimentos feitos em outras entidades, a política
          de distribuição de dividendos, a política de reinvestimento de lucros
          etc.
        </p>

        <hr className="mt-5"></hr>
        <h5 className="fw-bolder mt-4">Notas explicativas</h5>
        <p>
          As demonstrações contábeis devem ser complementadas por notas
          explicativas, quadros analíticos e outras demonstrações contábeis
          necessárias para uma plena avaliação da situação e da evolução
          patrimonial de uma empresa. As notas devem conter no mínimo a
          descrição dos critérios de avaliação dos elementos patrimoniais e das
          práticas contábeis adotadas, dos ajustes dos exercícios anteriores,
          reavaliações, ônus sobre ativos, detalhamento das dívidas de longo
          prazo, do capital e dos investimentos relevantes em outras empresas
          etc. As Notas explicativas têm por objetivo complementar as
          demonstrações contábeis mostrando os critérios contábeis utilizados
          pelas organizações, inclusive a composição do saldo de determinadas
          contas, os métodos de depreciação e critérios de avaliação dos
          elementos patrimoniais.
        </p>

        <hr className="mt-5"></hr>
        <h5 className="fw-bolder mt-4">Parecer dos auditores independentes</h5>
        <p>
          No Brasil a Lei das Sociedades por Ações (Lei nº 6.404 de 15/12/1976,
          sendo alterada em parte pela Lei nº 11.638 de 28/12/2007), determina
          que as demonstrações contábeis sejam auditadas por auditores
          independentes registrados na CVM (Comissão de Valores Mobiliários).
          Normas específicas também exigem que as instituições subordinadas ao
          Banco Central do Brasil, à Superintendência de Seguros Privados e
          outras também tenham suas demonstrações contábeis auditadas. Em países
          com economias mais avançadas, onde a auditoria é uma obrigatoriedade
          para a grande maioria das empresas e entidades, inclusive as
          governamentais, quando não por lei, por exigência natural da sociedade
          e da comunidade de negócios; empréstimos, relações comerciais,
          transações importantes e linhas de crédito, normalmente só são
          concretizados nestes países, quando acompanhadas de demonstrações
          contábeis avalizadas por auditores independentes; nos EUA esta
          necessidade é ainda maior após o advento da SOX ([The Sarbanes-Oxley
          Act of 2002] ou Sarbanes-Oxley compliance) e normalmente esta
          necessidade se reflete em todas filiadas americanas domiciliadas em
          outros países.
        </p>
      </section>
    </article>
  );
}
