import React, { useContext, useEffect, useState } from "react";
import "./coinTable.css";
import "../../../../styles/ouroninas.css";
import spin from "../../../../assets/spinner.gif";
import {
  getRemessas,
  getRemessasMore,
} from "../../../../services/cotacoes";
import { ReturnName, ReturnNameGold } from "../../../../utils/coins";
import { OuroContext } from "../../../context/cotacoes/cotacoesService";
import styled from "styled-components";
import {  getCotacaoAtualMoeda } from "../../../../services/api";
import CountryFlags from "../../../../utils/countryFlags";
import { useCardCoinStore, useForeignCoinStore, useStoresMoneygram } from "../../../../services/useStore/useCoinStore";

export function BolsaNovaYork({ error }) {
  const [ouro, setOuro] = useState();
  const [load, setLoad] = useState(true);
  useEffect( () => {
    if (!ouro) {
      getOuro();
    }
  }, [ouro]);


  function getOuro() {
    getCotacaoAtualMoeda("XAUUSD")
      .then(setOuro)
      .finally(() => {
        setLoad(false);
      });
  }

  return (
    <section className="p-2 overflow-auto">
      {load && (
        <div className="col-12 d-flex justify-content-center h-100 mt-5">
          <Spin alt="imagem de load" src={spin} />
        </div>
      )}
      {ouro && (
        <table className="table table-hover table-bordered cTable">
          {
            <tbody className="overflow-auto">
              <tr>
                <td className="col-6 text-capitalize">Cotação do Ouro Bolsa de Nova York</td>
                <td className="col-6 text-capitalize">
                  {parseFloat(ouro?.ovd).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 4,
                    maximumFractionDigits: 4,
                  })}
                </td>
              </tr>

            </tbody>
          }
        </table>
      )}
      {error && (
        <div className="col-12 d-flex justify-content-center h-100 mt-5">
          Dados não disponíveis no momento. {error}
        </div>
      )}
    </section>
  );
}

export function OuroAtivoFinanceiroCotacao() {
  const { productsOuro, addOuro } = useContext(OuroContext);
  const [coin, setCoin] = useState()
  useEffect(() => {
    if (productsOuro.length === 0) {
      addOuro();
    }
    let filter = productsOuro.filter((item) =>
      item.codIso.includes("OURO BARRA (1g)")
    );
    setCoin(filter[0]);
  }, [productsOuro, addOuro]);

  return (
    <section className="p-2 overflow-auto">
      {coin && (
        <>
          <table className="table table-hover table-bordered cTable">
            <thead>
              <tr>
                <th scope="col">Barra de Ouro / Gramatura</th>
                <th scope="col">Você Compra</th>
                <th scope="col">Você Vende</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Cotação da Grama do Ouro 999 Mercantil</td>
                <td>
                  {parseFloat(coin?.calculoVenda).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                    minimumFractionDigits: 4,
                    maximumFractionDigits: 4,
                  })}
                </td>
                <td>
                  {parseFloat(coin?.calculoCompra).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                    minimumFractionDigits: 4,
                    maximumFractionDigits: 4,
                  })}
                </td>
              </tr>

            </tbody>
          </table>
          <Legend>
            *A OM comercializa barras de ouro nas gramaturas: 1g, 2g, 5g, 10g, 25g, 31g (onça troy), 50g, 100g, 250g, 500g e 1000g (1kg).
            <br />
            **As barras de 1 e 2 gramas são produtos exclusivos da OM, sendo a única DTVM a comercializar no formato de barra padrão, outras empresas comercializam estas gramaturas no formato laminado.
            <br />
            ***Valores da cotação de venda, baseados em 1 grama.
            <br />
            ****Cotações para compras e vendas no site. Central de vendas, lojas próprias e parceiros autorizados podem praticar outros valores.
            <br />
            *****O material vendido pela OM é adquirido no mercado Balcão e no mercado primário, extraído de áreas de produção mineral localizada no Brasil, oriundas de Permissões de Lavras Garimpeiras (PLG), autorizadas e conferidas pela Agência Nacional de Mineração (ANM).
            <br />
            ******Todo ouro comercializado na OM possui teor de pureza 999 - 24k, são lacrados individualmente com selos holográficos tridimensionais que garantem a segurança e a qualidade do produto, acompanha Nota Fiscal Eletrônica (NF-e), Garantia de Recompra e Certificado de Origem do Metal.
            <br/>
            *******O valor da cotação de compra, ou seja, a OM comprando o ouro do cliente, depende do peso, teor e tipo do ouro, entre em contato para avaliação.
          </Legend>
        </>
      )}
    </section>
  );
}
export function OuroAtivoFinanceiroSimulacao() {
  const { productsOuro, addOuro } = useContext(OuroContext);

  useEffect(() => {
    if (productsOuro.length === 0) {
      addOuro();
    }
  }, [productsOuro, addOuro]);

  return (
    <section className="p-2 overflow-auto">
      {productsOuro.length > 0 && (
        <>
          <table className="table table-hover table-bordered cTable">
            <thead>
              <tr>
                <th scope="col">Barras de Ouro / Gramaturas</th>
                <th scope="col">Você Compra</th>
                <th scope="col">Você Vende</th>
              </tr>
            </thead>
            <tbody>
              {productsOuro?.map((moeda) => {
                return (
                  <tr>
                    <td>{ReturnNameGold(moeda?.codIso)}</td>

                    <td>
                      {parseFloat(moeda?.calculoGramaturaVenda).toLocaleString(
                        "pt-br",
                        {
                          style: "currency",
                          currency: "BRL",
                          minimumFractionDigits: 4,
                          maximumFractionDigits: 4,
                        }
                      )}
                    </td>
                    <td>
                      {parseFloat(moeda?.calculoGramaturaCompra).toLocaleString(
                        "pt-br",
                        {
                          style: "currency",
                          currency: "BRL",
                          minimumFractionDigits: 4,
                          maximumFractionDigits: 4,
                        }
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Legend>
            *A OM comercializa barras de ouro nas gramaturas: 1g, 2g, 5g, 10g, 25g, 31g (onça troy), 50g, 100g, 250g, 500g e 1000g (1kg).
            <br />
            **As barras de 1 e 2 gramas são produtos exclusivos da OM, sendo a única DTVM a comercializar no formato de barra padrão, outras empresas comercializam estas gramaturas no formato laminado.
            <br />
            ***Valores da cotação de venda, baseados em 1 grama.
            <br />
            ****Cotações para compras e vendas no site. Central de vendas, lojas próprias e parceiros autorizados podem praticar outros valores.
            <br />
            *****O material vendido pela OM é adquirido no mercado Balcão e no mercado primário, extraído de áreas de produção mineral localizada no Brasil, oriundas de Permissões de Lavras Garimpeiras (PLG), autorizadas e conferidas pela Agência Nacional de Mineração (ANM).
            <br />
            ******Todo ouro comercializado na OM possui teor de pureza 999 - 24k, são lacrados individualmente com selos holográficos tridimensionais que garantem a segurança e a qualidade do produto, acompanha Nota Fiscal Eletrônica (NF-e), Garantia de Recompra e Certificado de Origem do Metal.
            <br/>
            *******O valor da cotação de compra, ou seja, a OM comprando o ouro do cliente, depende do peso, teor e tipo do ouro, entre em contato para avaliação.
          </Legend>
        </>
      )}
    </section>
  );
}
export function MoedasEstrangeirasEspecie() {
  const { data, getData, isLoading } = useForeignCoinStore();

  useEffect(() => {
     getData();
  }, [getData])


  return (
    <section className="p-2 overflow-auto">
      {isLoading && (
        <div className="col-12 d-flex justify-content-center h-100 mt-5">
          <Spin alt="imagem de load" src={spin} />
        </div>
      )}
      {data.length > 0 && (
        <>
          <table className="table table-hover table-bordered cTable">
            <thead>
              <tr>
                <th scope="col">Moedas Estrangeiras</th>
                <th scope="col">Você Compra</th>
                <th scope="col">Você Vende</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <CountryFlags cod={item?.codIso} />
                      {ReturnName(item?.codIso)} {" - "}
                      {item.codIso === "EUSCOM" ? "USD" : item.codIso}
                    </td>
                    <td>
                      {" "}
                      {parseFloat(item?.calculoIOFVenda).toLocaleString(
                        "pt-br",
                        {
                          style: "currency",
                          currency: "BRL",
                          minimumFractionDigits: 4,
                          maximumFractionDigits: 4,
                        }
                      )}
                    </td>
                    <td>
                      {parseFloat(item?.calculoIOFCompra).toLocaleString(
                        "pt-br",
                        {
                          style: "currency",
                          currency: "BRL",
                          minimumFractionDigits: 4,
                          maximumFractionDigits: 4,
                        }
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Legend>
            *Cotações para compras e vendas no site. Central de vendas, lojas próprias e parceiros autorizados podem praticar outros valores.
          </Legend>
        </>
      )}
    </section>
  );
}
export function MoedasEstrangeirasCartao() {
  const { data, getData, isLoading} = useCardCoinStore();

  useEffect (() => {
    getData();
  }, [getData])


  return (
    <section className="p-2 overflow-auto">
      {isLoading && (
        <div className="col-12 d-flex justify-content-center h-100 mt-5">
          <Spin alt="imagem de load" src={spin}/>
        </div>
      )}
      {data.length > 0 && (
        <>
          <table className="table table-hover table-bordered cTable">
            <thead>
              <tr>
                <th scope="col">Moedas Estrangeiras</th>
                <th scope="col">Você Carrega</th>
                <th scope="col">Você Vende</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item) => {
                return (
                  <tr>
                    <td>
                      <CountryFlags cod={item?.codIso} />
                      {ReturnName(item?.codIso)}
                      {" - "}
                      {item.codIso === "EUSCOM" ? "USD" : item.codIso}
                    </td>
                    <td>
                      {" "}
                      {parseFloat(item?.calculoIOFVenda).toLocaleString(
                        "pt-br",
                        {
                          style: "currency",
                          currency: "BRL",
                          minimumFractionDigits: 4,
                          maximumFractionDigits: 4,
                        }
                      )}
                    </td>
                    <td>
                      {parseFloat(item?.calculoIOFCompra).toLocaleString(
                        "pt-br",
                        {
                          style: "currency",
                          currency: "BRL",
                          minimumFractionDigits: 4,
                          maximumFractionDigits: 4,
                        }
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Legend>
            *Cotações para cargas e vendas no site. Central de vendas, lojas próprias e parceiros autorizados podem praticar outros valores.
          </Legend>
        </>
      )}
    </section>
  );
}
export function RemessasInternacionais() {
  const [load, setLoad] = useState(true);
  const [moedas, setMoedas] = useState([]);

  useEffect(() => {
    setInterval(() => {
      getMoedas();
    }, 100000)
  },[])
  useEffect(() => {
    if (moedas.length === 0) {
      getMoedas();
    }
  },[]);

  function filter(moedas) {
    let filter = moedas.sort((a, b) => {
      return a["nomeMoeda"] < b["nomeMoeda"] ? -1 : 1;
    });

    setMoedas(filter);
  }
  function getMoedas() {
    getRemessas()
      .then((resp) => filter(resp))
      .finally(() => setLoad(false));
  }
  return (
    <section className="p-2 overflow-auto">
      {load && (
        <div className="col-12 d-flex justify-content-center h-100 mt-5">
          <Spin alt="imagem de load" src={spin} />
        </div>
      )}
      {moedas.length > 0 && (
        <>
          <table className="table table-hover table-bordered cTable">
            <thead>
              <tr>
                <th scope="col">Remessas Internacionais</th>
                <th scope="col">Você Envia</th>
                <th scope="col">Você Recebe</th>
              </tr>
            </thead>
            <tbody>
              {moedas?.map((moeda) => {
                return (
                  <tr>
                    <td>
                      <CountryFlags cod={moeda?.codIso} />
                      {ReturnName(moeda?.codIso)} {" - "}
                      {moeda.codIso === "EUSCOM" ? "USD" : moeda.codIso}
                    </td>
                    <td>
                      {parseFloat(moeda?.calculoVenda).toLocaleString(
                        "pt-br",
                        {
                          style: "currency",
                          currency: "BRL",
                          minimumFractionDigits: 4,
                          maximumFractionDigits: 4,
                        }
                      )}
                    </td>
                    <td>
                      {" "}
                      {parseFloat(moeda?.calculoCompra).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                        minimumFractionDigits: 4,
                        maximumFractionDigits: 4,
                      })}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Legend>
            *Valor estimado, pode sofrer variações de acordo com o banco
            recebedor. <br />
            **Cotações para envios e recebimentos no site. Central de vendas, lojas próprias e parceiros autorizados podem praticar outros valores.
          </Legend>
        </>
      )}
    </section>
  );
}

export function Moneygram() {
  const { data, getData, isLoading} = useStoresMoneygram();

  useEffect (async() => {
    await getData();
  }, [])



  return (
    <section className="p-2 overflow-auto">
      {isLoading && (
        <div className="col-12 d-flex justify-content-center h-100 mt-5">
          <Spin alt="imagem de load" src={spin} />
        </div>
      )}
      {data.length > 0 && (
        <>
          <table className="table table-hover table-bordered cTable">
            <thead>
              <tr>
                <th scope="col">Moneygram</th>
                <th scope="col">Você Envia</th>
                <th scope="col">Você Recebe</th>
              </tr>
            </thead>
            <tbody>
            {data?.map((item) => {
                return (
                  <tr key={item.id}>
                    <td><CountryFlags cod="MONEYGRAM" />
                      MONEYGRAM
                    </td>
                    <td>
                      {" "}
                      {parseFloat(item?.calculoIOFVenda).toLocaleString(
                        "pt-br",
                        {
                          style: "currency",
                          currency: "BRL",
                          minimumFractionDigits: 4,
                          maximumFractionDigits: 4,
                        }
                      )}
                    </td>
                    <td>
                      {parseFloat(item?.calculoIOFCompra).toLocaleString(
                        "pt-br",
                        {
                          style: "currency",
                          currency: "BRL",
                          minimumFractionDigits: 4,
                          maximumFractionDigits: 4,
                        }
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

        </>
      )}
    </section>
  );
}


export function More() {
  const [load, setLoad] = useState(false);
  const [data, setData] = useState([])

  useEffect(() =>{
    getMoedas();
    const intervalId = setInterval(() =>{
      getMoedas();

    }, 100000)

    async function getMoedas() {
      setLoad(true);
      await getRemessasMore()
        .then(setData)
        .catch((err) => err)
        .finally(() => setLoad(false));
    }

    return () => {
      clearInterval(intervalId);
    };
  },[])



  return (
    <section className="p-2 overflow-auto">
      {load && (
        <div className="col-12 d-flex justify-content-center h-100 mt-5">
          <Spin alt="imagem de load" src={spin} />
        </div>
      )}
      {data.length > 0 && (
        <>
          <table className="table table-hover table-bordered cTable">
            <thead>
              <tr>
                <th scope="col">More Payment</th>
                <th scope="col">Você Envia</th>
              </tr>
            </thead>
            <tbody>
            {data?.map((item) => {
                return (
                  <tr key={item.id}>
                    <td><CountryFlags cod="MORE" />
                      MORE PAYMENT
                    </td>
                    <td>
                      {" "}
                      {parseFloat(item?.calculoVenda).toLocaleString(
                        "pt-br",
                        {
                          style: "currency",
                          currency: "BRL",
                          minimumFractionDigits: 4,
                          maximumFractionDigits: 4,
                        }
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

        </>
      )}
    </section>
  );
}


const Legend = styled.p`
  text-align: start;
  color: #010101;
  @media(max-width:400px){
    font-size:8px
  }
  @media(min-width:401px){

    font-size: 10px;
  }
`;

const Spin = styled.img`
width: 60px;
margin-bottom: 10px;
`