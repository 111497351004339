import React from 'react';
import './politicas.css';
import img from '../../../assets/newImages/politicaSgi.png';

export default function PoliticaLavagemDinheiro() {
  return (
    <article className="art-s mb-5 p-text">
      <section className="row m-0  sectionInit ">
        <div className="c-init  ">
          <div className="c-init__text col-6  d-flex flex-column sect">
            <p className="fw-bolder text-decoration-underline">
              POLÍTICA DE PREVENÇÃO À LAVAGEM DE DINHEIRO E AO FINANCIAMENTO DO
              TERRORISMO (PLD-FT) LEI 12.683/2012
            </p>
            <p>
              Política elaborada por Bruna Finarde (Compliance), revisada por
              Carlos Henrique (Diretor de Compliance) e aprovada pela Diretoria
              Executiva, destinada a todas as partes interessadas, ou seja, aos
              clientes e usuários dos produtos oferecidos pela{' '}
              <strong>OM DTVM</strong>, a comunidade interna (funcionários,
              colaboradores (pessoas contratadas fora do regime CLT que executam
              atividades em nome da <strong>OM DTVM</strong>), estagiários,
              jovens aprendizes, correspondentes cambiais e postos de
              atendimento) e, claro, para todos aqueles que querem conhecer o
              posicionamento da <strong>OM DTVM</strong> sobre o tema.
            </p>
          </div>
          <div className="col-6 c-init__images">
            <img src={img} className="images" alt="politica-privacidade"></img>
          </div>
        </div>
        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                CONFORMIDADE E AMBITO DE APLICAÇÃO
              </li>
              <li className="list-unstyled">
                <p>
                  A Política de Prevenção à Lavagem de Dinheiro e Financiamento
                  ao Terrorismo está em conformidade com a Lei Nº 9.613{' '}
                  <sup>1</sup>, de 3 de março de 1998 alterada pela Lei Nº
                  12.683 <sup>2</sup>, de 09 de julho de 2012; Lei Nº 13.260{' '}
                  <sup>3</sup>, de 16 de março de 2016; Lei Nº 13.810{' '}
                  <sup>4</sup>, de 8 de março de 2019; Circular BCB N° 3.978{' '}
                  <sup>5</sup>, de 23 de janeiro de 2020; Carta Circular BCB Nº
                  4.001 <sup>6</sup>, de 29 de janeiro de 2020; Resolução CMN Nº
                  4.968 <sup>7</sup>, de 25 de novembro de 2021; Resolução BCB
                  Nº 44 <sup>8</sup>, de 24 de novembro de 2020, Resolução BCB
                  Nº 277, de 31 de dezembro de 2022 <sup>9</sup>; Resolução ANM
                  Nº 129 <sup>10</sup>, de 23 de fevereiro de 2023; Instrução
                  Normativa BCB Nº 406, de 31 de julho de 2023 <sup>11</sup> e
                  dispõe sobre os princípios e diretrizes que, consideradas a
                  relevância e proporcionalidade, devem ser observadas no seu
                  estabelecimento e implementação.
                </p>
              </li>
            </ul>
            <span
              style={{
                fontSize: '0.8em',
                textAlign: 'left',
                marginTop: '20px',
              }}
            >
              <p>
                <sup>1</sup> Dispõe sobre os crimes de "lavagem" ou ocultação de
                bens, direitos e valores; a prevenção da utilização do sistema
                financeiro para os ilícitos previstos nesta Lei; cria o Conselho
                de Controle de Atividades Financeiras - COAF, e dá outras
                providências.
              </p>
              <p>
                <sup>2</sup> Altera a Lei nº 9.613, de 3 de março de 1998, para
                tornar mais eficiente a persecução penal dos crimes de lavagem
                de dinheiro.
              </p>
              <p>
                <sup>3</sup> Regulamenta o disposto no inciso XLIII do art. 5º
                da Constituição Federal, disciplinando o terrorismo, tratando de
                disposições investigatórias e processuais e reformulando o
                conceito de organização terrorista; e altera as Leis n º 7.960,
                de 21 de dezembro de 1989, e 12.850, de 2 de agosto de 2013.
              </p>
              <p>
                <sup>4</sup> Dispõe sobre o cumprimento de sanções impostas por
                resoluções do Conselho de Segurança das Nações Unidas, incluída
                a indisponibilidade de ativos de pessoas naturais e jurídicas e
                de entidades, e a designação nacional de pessoas investigadas ou
                acusadas de terrorismo, de seu financiamento ou de atos a ele
                correlacionados; e revoga a Lei nº 13.170, de 16 de outubro de
                2015.
              </p>
              <p>
                <sup>5</sup> Dispõe sobre a política, os procedimentos e os
                controles internos a serem adotados pelas instituições
                autorizadas a funcionar pelo Banco Central do Brasil visando à
                prevenção da utilização do sistema financeiro para a prática dos
                crimes de “lavagem” ou ocultação de bens, direitos e valores, de
                que trata a Lei nº 9.613, de 3 de março de 1998, e de
                financiamento do terrorismo, previsto na Lei nº 13.260, de 16 de
                março de 2016.
              </p>
              <p>
                <sup>6</sup> Divulga relação de operações e situações que podem
                configurar indícios de ocorrência dos crimes de "lavagem" ou
                ocultação de bens, direitos e valores, de que trata a Lei nº
                9.613, de 3 de março de 1998, e de financiamento ao terrorismo,
                previstos na Lei nº 13.260, de 16 de março de 2016, passíveis de
                comunicação ao Conselho de Controle de Atividades Financeiras
                (Coaf).
              </p>
              <p>
                <sup>7</sup> Dispõe sobre os sistemas de controles internos das
                instituições financeiras e demais instituições autorizadas a
                funcionar pelo Banco Central do Brasil.
              </p>
              <p>
                <sup>8</sup> Estabelece procedimentos para a execução pelas
                instituições autorizadas a funcionar pelo Banco Central do
                Brasil das medidas determinadas pela Lei nº 13.810, de 8 de
                março de 2019, que dispõe sobre o cumprimento de sanções
                impostas por resoluções do Conselho de Segurança das Nações
                Unidas, incluída a indisponibilidade de ativos de pessoas
                naturais e jurídicas e de entidades, e a designação nacional de
                pessoas investigadas ou acusadas de terrorismo, de seu
                financiamento ou de atos a ele correlacionados.
              </p>
              <p>
                <sup>9</sup> Regulamenta a Lei nº 14.286, de 29 de dezembro de
                2021, em relação ao mercado de câmbio e ao ingresso no País e à
                saída do País de valores em reais e em moeda estrangeira, e dá
                outras providências.
              </p>
              <p>
                <sup>10</sup> Dispõe sobre cumprimento dos deveres de prevenção
                à lavagem de dinheiro e ao financiamento do terrorismo e da
                proliferação de armas de destruição em massa - PLD/FTP,
                legalmente atribuídos na forma dos arts. 10 e 11 da Lei nº
                9.613, de 3 de março de 1998.
              </p>
              <p>
                <sup>11</sup> Orienta as instituições autorizadas a funcionar
                pelo Banco Central do Brasil quanto aos procedimentos a serem
                adotados quando da compra de ouro.
              </p>
            </span>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                VALIDADE E DISPONIBILIZAÇÃO
              </li>
              <li className="list-unstyled">
                <p>
                  O conteúdo desta Política é de responsabilidade do Diretor de
                  Prevenção à Lavagem de Dinheiro e Financiamento ao Terrorismo
                  da <strong>OM DTVM</strong>, como o responsável pelo seu
                  cumprimento. A gestão desta Política (elaboração,
                  conformidade, guarda, divulgação, controle de atualizações e
                  adesão) faz parte do Programa de Conformidade (Compliance) da
                  <strong> OM DTVM</strong>.
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  Esta Política de Prevenção à Lavagem de Dinheiro e
                  Financiamento ao Terrorismo é reavaliada e republicada a cada
                  ano, quando houver alterações na <strong>OM DTVM</strong> ou
                  em razão de requerimentos legais e/ou de efetividade que
                  justifiquem a sua atualização antecipada. A sua existência,
                  assim como suas novas versões são aprovadas e comunicadas ao
                  público interno, com assinatura eletrônica, através da
                  plataforma Clicksign{' '}
                  <a
                    rel="noreferrer"
                    className="text-decoration-none"
                    href={'https://www.clicksign.com/'}
                    target="_blank"
                  >
                    (https://www.clicksign.com/)
                  </a>{' '}
                  e disponibilizada na rede pública interna (Intranet ou Teams)
                  e/ou sítio da <strong>OM DTVM</strong>.
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                OBJETIVO DESTA POLÍTICA
              </li>
              <p>
                Divulgar os princípios e diretrizes da <strong>OM DTVM</strong>{' '}
                que norteiam as ações de Prevenção à Lavagem de Dinheiro e
                Financiamento ao Terrorismo nos negócios e na relação com as
                partes interessadas, bem como sobre as ações estratégicas
                relacionadas à sua governança, inclusive para fins do
                gerenciamento integrado de riscos sendo, considerados como
                principais: risco operacional, risco legal, risco de imagem,
                risco de liquidez, risco reputacional e risco social, ambiental
                e climático.
              </p>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                CONCEITO E FASES DA LAVAGEM DE DINHEIRO
              </li>
              <li className="list-unstyled">
                <p>
                  Expressão que se refere a prática econômico-financeira que têm
                  por finalidade dissimular ou esconder a origem ilícita de
                  determinados ativos financeiros, de forma que aparentem uma
                  origem lícita ou que, pelo menos, a origem ilícita seja
                  difícil de demonstrar ou provar. Não existe pacificado um
                  conceito único sendo, o melhor, o apresentado na própria lei:
                  “Art. 1º Ocultar ou dissimular a natureza, origem,
                  localização, disposição, movimentação ou propriedade de bens,
                  direitos ou valores provenientes, direta ou indiretamente, de
                  crime”.
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  <ul>
                    <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                      COLOCAÇÃO
                    </li>
                    <li className="list-unstyled">
                      Nessa etapa, os bens ou valores ilegais são inseridos na
                      economia. A colocação pode acontecer por meio da compra de
                      bens, imóveis ou obras de arte, por exemplo. Uma das
                      principais características dessa etapa é que ela acontece,
                      principalmente, por meio da fragmentação do valor
                      monetário em pequenas quantias. Dessa forma, a
                      movimentação financeira não precisa ser comunicada e passa
                      despercebida aos órgãos fiscalizadores.
                    </li>
                  </ul>
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  <ul>
                    <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                      OCULTAÇÃO
                    </li>
                    <li className="list-unstyled">
                      Na fase da ocultação, o principal objetivo é esconder a
                      origem do dinheiro advindo de infrações penais. Por meio
                      de ações como transferências bancárias ou pela utilização
                      de contas fantasmas (abertas com documentos reais, em
                      nomes de laranjas ou pessoas que não existem), o
                      rastreamento dos bens ou recursos financeiros ilegais
                      acaba sendo dificultado.
                    </li>
                  </ul>
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  <ul>
                    <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                      INTEGRAÇÃO
                    </li>
                    <li className="list-unstyled">
                      Como última das etapas da lavagem de dinheiro, os recursos
                      ilícitos são formalmente reinseridos na economia. Essa
                      integração pode ocorrer por meio de investimentos em
                      negócios lícitos ou compra de ativos com documentos
                      supostamente legais.
                    </li>
                  </ul>
                </p>
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                FINANCIAMENTO AO TERRORISMO
              </li>
              <li className="list-unstyled">
                <p>
                  Caracterizado pelo apoio financeiro, por qualquer meio, ao
                  terrorismo ou aquele que incentiva, planeja ou comete ato de
                  terrorismo. O financiamento do terrorismo tem como objetivo
                  fornecer fundos para atividades terroristas. Essa arrecadação
                  de fundos pode acontecer de diversas formas, entre elas fontes
                  lícitas – tais como doações pessoais e lucros de empresas e
                  organizações de caridade – bem como a partir de fontes
                  criminosas – como o tráfico de drogas, o contrabando de armas,
                  bens e serviços tomados indevidamente à base da força, fraude,
                  sequestro e extorsão.
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  Neste sentido, o terrorismo consiste na prática por um ou mais
                  indivíduos dos atos, por razões de xenofobia, discriminação ou
                  preconceito de raça, cor, etnia e religião, quando cometidos
                  com a finalidade de provocar terror social ou generalizado,
                  expondo a perigo pessoa, patrimônio, a paz pública ou a
                  incolumidade pública e está tipificado, através da Lei nº
                  13.260, de 16 de março de 2016.
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  Neste sentido, os procedimentos de identificação, qualificação
                  e monitoramento de clientes da <strong>OM DTVM </strong>
                  incluem, <u>obrigatoriamente</u>, verificar se o cliente ou
                  potencial cliente, seus representantes, sócios ou procuradores
                  são pessoas cujos nomes constam na lista de empresas ou
                  pessoas sancionadas pelo Conselho de Segurança das Nações
                  Unidas (CSNU) por associação ao terrorismo ou ao seu
                  financiamento.
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  Cabe ressaltar que no processo de aceitação, caso algum
                  proponente (incluindo terceiros) esteja na lista CSNU, o
                  cadastro em hipótese alguma poderá ser efetivado, sendo
                  caracterizada como inaceitável tal relação. Em ambas as
                  situações (aceitação ou monitoramento), as constatações são
                  passíveis de reporte às autoridades competentes, em
                  consonância com os documentos normativos em vigor.
                </p>
              </li>
            </ul>
          </p>
        </section>
        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                PROGRAMA DE PREVENÇÃO À LAVAGEM DE DINHEIRO
              </li>
              <p>
                O Programa de Prevenção à Lavagem de Dinheiro e Financiamento ao
                Terrorismo da <strong>OM DTVM</strong> é constituído por 6
                pilares:{' '}
                <strong>
                  Política de Prevenção à Lavagem de Dinheiro e Financiamento ao
                  Terrorismo
                </strong>
                ; Avaliação Interna de Riscos; Manuais de Procedimentos
                Operacionais; Avaliação da Efetividade; Treinamento de Prevenção
                à Lavagem de Dinheiro e Financiamento ao Terrorismo e, Auditoria
                Interna.
              </p>
              <p>
                Os pilares estão em linha com a Lei nº 9.613, de 03 de março de
                1998, Resolução CMN Nº 4.968, de 25 de novembro de 2021 e, se
                mostram compatíveis com a natureza, porte, complexidade,
                estrutura, perfil de risco e modelo de negócio da{' '}
                <strong>OM DTVM</strong>.
              </p>
              <p>
                O Programa de Prevenção à Lavagem de Dinheiro é baseado em
                princípios e diretrizes que buscam prevenir e mitigar os riscos
                relacionados à utilização da <strong>OM DTVM</strong> para
                práticas de lavagem de dinheiro e financiamento do terrorismo.
              </p>
            </ul>
          </p>
        </section>
        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                DOS PRINCÍPIOS
              </li>
              <p>
                A <strong>OM DTVM</strong> busca oferecer a todos os seus
                funcionários, colaboradores (pessoas contratadas fora do regime
                CLT que executam atividades em nome da <strong>OM DTVM</strong>
                ), estagiários, jovens aprendizes, correspondentes cambiais e
                postos de atendimento, condições de trabalho adequadas e uma
                cultura organizacional que enfatize o papel de cada agente no
                processo de Prevenção à Lavagem de Dinheiro e Financiamento ao
                Terrorismo.
              </p>
              <p>
                Neste sentido a <strong>OM DTVM</strong> está empenhada em
                conduzir os seus negócios de forma consistente com os mais
                elevados padrões éticos e em conformidade com todas as leis,
                regulamentos e normas vigentes aplicáveis, bem como promove uma
                cultura organizacional que repudia qualquer ação que possa
                contribuir para com a lavagem de dinheiro e financiamento do
                terrorismo e:
              </p>
              <p>
                Não permite que seja associada à lavagem de dinheiro e
                financiamento ao terrorismo, bem como que suas operações sejam
                utilizadas para viabilizar e/ou facilitar tais atos e, conhece e
                gerencia os riscos inerentes e mantém mecanismos de
                monitoramento e controle para diagnosticar e minimizar os riscos
                referentes à lavagem de dinheiro e financiamento do terrorismo
                relacionado com partes interessadas.
              </p>
              <p>
                O conhecimento de qualquer indício de Lavagem de Dinheiro e
                Financiamento ao Terrorismo ou outro ato ilícito deverá ser
                comunicado à área de Compliance, que é responsável por averiguar
                as informações reportadas e, caso aplicável, após processo de
                análise, comunicar aos órgãos reguladores.
              </p>
              <p>
                A <strong>OM DTVM</strong> também disponibiliza na rede pública
                interna (Intranet ou Teams) e/ou sítio da{' '}
                <strong>OM DTVM</strong> um canal de comunicação (canal de
                denúncia) na qual as partes interessadas podem reportar
                anonimamente atos indevidos, referentes a Prevenção à Lavagem de
                Dinheiro e Financiamento ao Terrorismo.
              </p>
            </ul>
          </p>
        </section>
        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                DIRETRIZES
              </li>
              <p>
                A Diretoria da <strong>OM DTVM</strong> definiu seu compromisso
                de aprimorar os controles internos e estabelecer as diretrizes
                para o contínuo fortalecimento das práticas de Prevenção à
                Lavagem de Dinheiro e Financiamento ao Terrorismo sendo:
              </p>
              <ul>
                <li>
                  Proteção à reputação e a imagem da <strong>OM DTVM</strong> e
                  não realização de operações que possam configurar indícios de
                  ocorrências dos crimes de lavagem ou ocultação de bens,
                  direitos e valores ou de financiamento do terrorismo;
                </li>
              </ul>
              <br />
              <p>
                Desenvolvimento, implementação e disseminação de políticas,
                procedimentos e controles internos que promovam a participação
                efetiva da comunidade interna no esforço de prevenir o uso de
                produtos e serviços da <strong>OM DTVM</strong> para a lavagem
                de dinheiro e financiamento ao terrorismo, contribuindo para a
                formação de uma cultura organizacional de PLD-FT;
              </p>
              <ul>
                <li>
                  Adota procedimentos, no processo de identificação,
                  qualificação e monitoramento, para identificar se o agente
                  está identificado em lista restritiva do Conselho de Segurança
                  das Nações Unidas (CSNU) como pessoa ou empresa envolvida em
                  atos terroristas ou no financiamento de atos terroristas;
                </li>
              </ul>
              <br />
              <p>
                Proposição de ações para a constante disseminação da cultura de
                Prevenção à Lavagem de Dinheiro e Financiamento ao Terrorismo e,
                aprimoramento contínuo das políticas, normas e procedimentos de
                gerenciamento de riscos;
              </p>
              <ul>
                <li>
                  Disseminação de princípios éticos e regras de conduta
                  aplicáveis no cumprimento das regras relacionadas à Prevenção
                  à Lavagem de Dinheiro e Financiamento ao Terrorismo;
                </li>
              </ul>
              <br />
              <p>
                Treinamento adequado e periódico aos funcionários e
                colaboradores de todos os níveis, considerando a atividade
                desempenhada e a exposição ao risco de lavagem de dinheiro e,
                contemplando ações de conscientização e de avaliação de
                conhecimento;
              </p>
              <ul>
                <li>
                  Monitoramento sistêmico de operações visando a coerência nos
                  negócios realizados e indícios de lavagem de dinheiro e
                  financiamento ao terrorismo em consonância com a “Abordagem
                  Baseada em Risco” do GAFI;
                </li>
              </ul>
              <br />
              <p>
                Avaliação prévia de novos produtos e serviços, bem como da
                utilização de novas tecnologias, tendo em vista o risco de
                lavagem de dinheiro e financiamento ao terrorismo;
              </p>
              <ul>
                <li>
                  Realização de auditoria interna anual para a verificação da
                  aderência e da adequação das políticas, procedimentos e
                  controles internos de PLD-FT;
                </li>
              </ul>
              <br />
              <p>
                Designação, ao Banco Central do Brasil, de diretor responsável
                pela implementação e cumprimento das medidas relacionadas a
                PLD-FT, encarregado de gerenciar e supervisionar a aplicação das
                normas vigentes e dos princípios e diretrizes aqui estabelecidos
                e;
              </p>
              <ul>
                <li>
                  Condução de forma sigilosa os processos de registro, análise e
                  comunicação às autoridades competentes de operações
                  financeiras com indícios de lavagem de dinheiro.
                </li>
              </ul>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                PAPÉIS E RESPONSABILIDADES
              </li>
              <li className="list-unstyled">
                <p>
                  <ul>
                    <li>
                      <strong>DIRETORIA EXECUTIVA:</strong> Responsável por
                      realizar a avaliação prévia de operações, produtos e
                      serviços, bem como da utilização de novas tecnologias,
                      tendo em vista o risco de lavagem de dinheiro e
                      financiamento ao terrorismo; por aprovar as diretrizes
                      desta política e suas respectivas alterações; assegurar a
                      adoção de melhorias corretivas e preventivas para garantir
                      a efetividade desta; Acompanhar a efetividade das
                      atividades e das ações relacionadas à PLD-FT e, avaliação
                      da continuidade do relacionamento relacionada ao
                      descumprimento de condições originalmente pactuadas
                      identificadas no processo de monitoramento.
                    </li>
                  </ul>
                  <br />
                  <p>
                    <strong>DIRETOR DE COMPLIANCE:</strong> Aprovar o início da
                    relação de negócios em caso de insuficiência de informações
                    relativas ao processo de qualificação, desde que não haja
                    prejuízo aos procedimentos de monitoramento e seleção, bem
                    como aprovar a manutenção de Pessoa Exposta Politicamente
                    (PEP) e agentes inseridos em monitoramento de especial
                    atenção e, avaliação da continuidade do relacionamento
                    relacionada ao descumprimento de condições originalmente
                    pactuadas identificadas no processo de monitoramento.
                  </p>
                  <ul>
                    <li>
                      <strong>AUDITORIA INTERNA:</strong> Responsável por
                      avaliar e propor medidas para aprimoramento e efetividade
                      de políticas, processos e procedimentos que a instituição
                      estabelece, sempre verificando a aderência as
                      regulamentações em vigor e aplicabilidade fática.
                    </li>
                  </ul>
                  <br />
                  <p>
                    <strong>RECURSOS HUMANOS:</strong> Atuar como primeira linha
                    de defesa na avaliação de candidatos durante o processo de
                    recrutamento e seleção seguindo as melhores práticas no que
                    tange ao processo de “Conheça Seu Funcionário – KYE”; apoiar
                    o time de Compliance / PLD-FT no monitoramento de
                    funcionários, colaboradores, estagiários e jovens
                    aprendizes; suportar anualmente o time de Onboarding &
                    Background Check (Cadastro) disponibilizando as informações
                    necessárias para avaliação periódica do processo de “Conheça
                    Seu Funcionário - KYE”; viabilizar programas de treinamento
                    periódicos para assegurar que todos os funcionários,
                    colaboradores (pessoas contratadas fora do regime CLT que
                    executam atividades em nome da <strong>OM DTVM</strong>),
                    estagiários e jovens aprendizes estejam devidamente
                    orientados e atualizados quanto às suas obrigações e
                    responsabilidades de acordo com a regulamentação aplicável.
                  </p>
                  <ul>
                    <li>
                      <strong>CAP – CONTAS À PAGAR (FACILITIES):</strong> Atuar
                      como primeira linha de defesa na avaliação de novos
                      fornecedores seguindo as melhores práticas no que tange ao
                      processo de “Conheça seu Fornecedor - KYS”; apoiar o time
                      de Compliance / PLD-FT no monitoramento de fornecedores e,
                      suportar anualmente o time de Onboarding & Background
                      Check (Cadastro) disponibilizando as informações
                      necessárias para avaliação periódica do processo de
                      “Conheça Seu Fornecedor - KYS”.
                    </li>
                  </ul>
                  <br />
                  <p>
                    <strong>PLD-FT:</strong> Responsável pelo monitoramento
                    tempestivo de clientes e operações, mediante a avaliação da
                    capacidade econômico financeira visando coerência nos
                    negócios realizados e indícios de lavagem de dinheiro e
                    financiamento ao terrorismo; implementar processos e
                    procedimentos para identificação, monitoramento e análise de
                    comportamentos, operações e/ou transações suspeitas de
                    lavagem de dinheiro e financiamento ao terrorismo; reportar
                    ao Diretor de Compliance ou Comitê Executivo comportamentos,
                    operações e/ou transações suspeitas para deliberação;
                    reportar ao COAF/UIF, após a devida deliberação, suspeitas
                    de lavagem de dinheiro e financiamento ao terrorismo;
                    realizar diligências sempre que necessário em clientes,
                    fornecedores e/ou parceiros; inclusão de clientes em
                    monitoramento de especial atenção.
                  </p>
                  <ul>
                    <li>
                      <strong>ONBOARDING & BACKGROUND CHECK (CADASTRO):</strong>{' '}
                      Cumprir as normas internas e regulamentos vigentes quanto
                      à Avaliação Interna de Riscos (AIR) relacionado aos
                      processos de identificação, qualificação e classificação
                      dos procedimentos de KYC, KYP, KYE e KYS; definir
                      procedimentos para identificação, qualificação e obtenção
                      de dados cadastrais visando a identificação e conhecimento
                      do cliente bem como garantir o atendimento regulatório;
                      definir controles para validação dos dados cadastrais
                      declarados pelos clientes; Garantir que o cadastro do
                      cliente esteja em conformidade com o status da Receita
                      Federal; Identificar o Beneficiário Final dos clientes
                      Pessoa Jurídica e, identificar clientes Pessoas Expostas
                      Politicamente (PEP), clientes em listas restritivas,
                      clientes que residem em região de fronteira ou possuem
                      nacionalidade em país considerado sensível para fins de
                      PLD-FT, ou que apresentam enquadramento em profissão de
                      risco ou atividades de risco, que seguem procedimento
                      particular com a efetivação do processo apenas após
                      autorização explicita do Diretor de Compliance.
                    </li>
                  </ul>
                  <br />
                  <p>
                    <strong>COMPLIANCE:</strong> Responsável pela elaboração,
                    conformidade, guarda, divulgação, controle de atualizações,
                    adesão e promoção da cultura organizacional de prevenção à
                    lavagem de dinheiro e financiamento ao terrorismo;
                    gerenciamento do sistema de normativo interno relacionado à
                    Compliance e Controles Internos tais como: avaliação interna
                    de risco (AIR), políticas, procedimentos, diretrizes, normas
                    e manuais, instruções normativas e recomendações
                    institucionais para conformidade do negócio; aderência da
                    instituição ao arcabouço legal, à regulamentação infralegal,
                    às recomendações dos órgãos de supervisão e aos códigos de
                    ética e de conduta, bem como prestar suporte a Diretoria
                    Executiva em assuntos relativos à Governança, Risco e
                    Compliance – GRC; atender as demandas e eventuais auditorias
                    de órgãos reguladores; análise de situações reportados pela
                    comunidade interna e canal de denúncia e, realizar os
                    procedimentos relacionados a indisponibilidade de ativos
                    estabelecida nas Resoluções do CSNU como: determinação a
                    execução de bloqueio dos ativos (indisponibilidade para
                    transferir, converter, trasladar, disponibilizar os ativos
                    ou deles dispor) de titularidade de cliente pessoa física ou
                    jurídica identificada como sancionada pelo Conselho de
                    Segurança das Nações Unidas e, comunicação ao Banco Central
                    do Brasil, ao Ministério da Justiça e Segurança Pública e ao
                    Conselho de Controle de Atividade Financeira (COAF) dessa
                    indisponibilidade de ativos ou das tentativas de sua
                    transferência, conforme disposto no artigo 4º da Resolução
                    BCB nº 44/20.
                  </p>
                  <ul>
                    <li>
                      <strong>TECNOLOGIA DA INFORMAÇÃO:</strong> Garantir que os
                      sistemas de PLDCFT estejam adequadamente em funcionamento,
                      garantindo a resolução de eventuais falhas no menor tempo
                      de resposta possível.
                    </li>
                  </ul>
                </p>
              </li>
            </ul>
          </p>
        </section>
        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                BENEFICIÁRIO FINAL
              </li>
              <p>
                A identificação do beneficiário final será imprescindível para
                aprovação cadastral e, assim, as informações de qualificação,
                relativas a clientes pessoas jurídicas abrangerão as pessoas
                naturais autorizadas a representá-la, bem como a cadeia de
                participação societária, até alcançar a pessoa natural
                caracterizada como beneficiário final.
              </p>

              <p>
                Excetuam-se dessa exigência as pessoas jurídicas constituídas
                sob a forma de companhia aberta ou entidade sem fins lucrativos,
                para as quais as informações cadastrais abrangerão as pessoas
                naturais autorizadas a representá-las, bem como seus
                controladores, administradores e diretores, se houver.
              </p>
              <p>
                Às entidades e organizações sem fins lucrativos serão exigidas
                informações cadastrais contemplando as pessoas naturais
                autorizadas a representá-las, bem como as pessoas físicas ou
                jurídicas em favor das quais essas entidades e organizações
                tenham sido constituídas.
              </p>

              <p>
                Nos casos em que não for possível identificar o beneficiário
                final, pelas características da composição jurídica e societária
                da organização, será requerido ao Diretor de Compliance a
                avaliação da pertinência de início de relacionamento.
              </p>
              <p>
                Cabe ressaltar que, o % mínimo de participação societária
                definido pela <strong>OM DTVM</strong> é 25%, ou seja, para
                clientes de risco elevado podem ser identificados os
                beneficiários finais com participação menor que 25%.
              </p>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                PESSOA EXPOSTA POLITICAMENTE (PEP)
              </li>
              <p>
                Pessoa que, nos últimos 5 anos, tenha desempenhado, no Brasil ou
                Exterior, cargo, emprego ou função pública relevante, assim como
                seus representantes, familiares e outras pessoas de seu
                relacionamento próximo como: familiares ou parentes, na linha
                reta ou colateral, até o segundo grau, o(a) cônjuge,
                companheiro(a), enteado(a), mandatário(a), mesmo que por
                instrumento particular, estreito colaborador; pessoa natural
                conhecida por ter qualquer tipo de estreita relação com PEP,
                sócio ou pessoa com participação conjunta em arranjos sem
                personalidade jurídica.
              </p>
              <p>
                No caso de clientes estrangeiros, será considerado como PEP a
                pessoa que exerce ou exerceu funções públicas proeminentes em um
                país estrangeiro, tais como chefes de estado ou de governo,
                políticos de alto nível, altos servidores governamentais,
                judiciais, do legislativo ou militares, dirigentes de empresas
                públicas ou dirigentes de partidos políticos.
              </p>
              <p>
                A <strong>OM DTVM</strong>, em suas análises cadastrais e em seu
                monitoramento interno, considerará sempre a condição de
                exposição política dos clientes pessoas físicas, dos
                beneficiários finais de pessoas jurídicas ou de representantes
                de organizações sem fins lucrativos, no Brasil e no exterior.
              </p>
              <p>
                O meio principal de identificação é a autoidentificação e, como
                ferramentas auxiliares são efetuadas consultas nos sistemas
                e-Guardian e RISC da empresa Advice Compliance Solutions e ao
                próprio SISCOAF.
              </p>
              <p>
                Para os clientes qualificados como Pessoa Exposta Politicamente
                (PEP), a <strong>OM DTVM</strong> adota procedimentos e
                controles internos compatíveis com essa qualificação; considera
                essa qualificação na classificação do cliente nas categorias de
                risco e avalia, através de detentor de cargo ou função de nível
                hierárquico superior ao do responsável pela autorização do
                relacionamento com o cliente, o início e manutenção do
                relacionamento com o cliente.
              </p>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                DEFINIÇÃO DE CLIENTE
              </li>
              <p>
                Considera-se cliente ativo toda pessoa natural ou jurídica que
                utilizou um serviço, realizou uma operação ou adquiriu um
                produto na <strong>OM DTVM</strong> nos últimos 12 meses sendo,
                neste sentido, caracterizado como prospect toda pessoa natural
                ou jurídica que tenha realizado o cadastro mas não tenha
                efetivamente utilizado um serviço, realizado uma operação ou
                adquirido um produto da <strong>OM DTVM</strong> e, cliente
                inativo aquele que utilizou um serviço, realizou uma operação ou
                adquiriu um produto da <strong>OM DTVM</strong> em prazo
                superior à 12 meses.
              </p>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                PROCESSO DE IDENTIFICAÇÃO
              </li>
              <p>
                Refere-se a obtenção, verificação e validação da autenticidade
                das informações de identificação do cliente, funcionário,
                parceiro ou fornecedor através da confrontação de informações
                com a Receita Federal do Brasil e disponíveis em bancos de dados
                públicos e bureau privados.
              </p>
              <p>
                No processo de identificação devem ser coletadas as informações
                destacadas nos manuais internos de Conheça Seu Cliente (KYC),
                Conheça Seu Funcionário (KYE), Conheça Seu Parceiro (KYP) e
                Conheça Seu Fornecedor (KYS) e obtido, no mínimo, o documento
                exigido pelo órgão regulador e/ou fiscalizador como Banco
                Central do Brasil (BCB), Agência Nacional de Mineração (ANM),
                Receita Federal do Brasil (RFB) e Ministério do Trabalho e
                Previdência (MTP).
              </p>
              <p>
                Importante destacar que, em linha com a Carta Circular BCB Nº
                4.001, de 29 de janeiro de 2020, a resistência ao fornecimento
                de informações necessárias para o início de relacionamento ou
                atualização cadastral, realizada a cada 12 meses, configura
                indício de lavagem de dinheiro e financiamento do terrorismo.
              </p>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                PROCESSO DE QUALIFICAÇÃO
              </li>
              <p>
                Refere-se a coleta, verificação e validação da informação do
                cliente, funcionário, parceiro ou fornecedor compatíveis com seu
                respectivo perfil de risco e deve se manter atualizada e ser
                reavaliada de forma permanente, de acordo com a evolução da
                relação de negócio.
              </p>
              <p>
                No processo de qualificação devem ser coletadas as informações
                destacadas nos manuais internos de Conheça Seu Cliente (KYC),
                Conheça Seu Funcionário (KYE), Conheça Seu Parceiro (KYP) e
                Conheça Seu Fornecedor (KYS) que incluem a verificação da
                condição de Pessoa Exposta Politicamente (PEP), bem como a
                verificação da condição de representante, familiar ou estreito
                colaborador dessas pessoas.
              </p>
              <p>
                Importante destacar que, em linha com a Carta Circular BCB Nº
                4.001, de 29 de janeiro de 2020 a resistência ao fornecimento de
                informações necessárias para o início de relacionamento ou
                atualização cadastral, realizada a cada 12 meses, configura
                indício de lavagem de dinheiro e financiamento do terrorismo.
              </p>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                PROCESSO DE CLASSIFICAÇÃO
              </li>
              <p>
                Refere-se a classificação do cliente, funcionário, parceiro e
                fornecedor nas categorias de risco definidas na Avaliação
                Interna de Risco (AIR) que usa, como base, as informações
                obtidas nos procedimentos de identificação e qualificação.
              </p>

              <p>
                A classificação deve ser realizada com base no perfil de risco e
                na natureza da relação de negócio e revista sempre que houver
                alterações no perfil de risco do cliente e na natureza da
                relação de negócio.
              </p>
              <p>
                Importante destacar que, em linha com a Carta Circular BCB Nº
                4.001, de 29 de janeiro de 2020, o fornecimento de auxílio ou
                informações, remunerados ou não, a cliente, funcionários,
                parceiros ou fornecedores em prejuízo do programa de Prevenção à
                Lavagem de Dinheiro e Financiamento ao Terrorismo da
                instituição, ou de auxílio para estruturar ou fracionar
                operações, burlar limites regulamentares ou operacionais,
                configura indício de lavagem de dinheiro e financiamento do
                terrorismo.
              </p>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                AVALIAÇÃO DA CONTINUIDADE DO RELACIONAMENTO
              </li>
              <p>
                O descumprimento de condições originalmente pactuadas
                identificadas no processo de monitoramento, bem como a
                identificação de novos fatos que possam oferecer indício de
                irregularidades ou de práticas indevidas por parte do cliente
                ensejarão a suspensão, bloqueio ou encerramento de
                relacionamento, além de outras providências internas
                eventualmente cabíveis.
              </p>

              <p>
                Caberá as estruturas de Onboarding & Background Check,
                Compliance e Prevenção à Lavagem de Dinheiro, a partir das
                evidências de desalinhamento com as políticas internas, acionar
                a área responsável pelo contato com o cliente, onde serão
                expostas todas as informações que desabonam a continuidade das
                negociações com o cliente cabendo, ao Diretor de Compliance ou
                Diretoria Executiva da <strong>OM DTVM</strong>, a decisão pela
                interrupção das negociações ou pela continuidade do
                relacionamento.
              </p>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                AVALIAÇÃO INTERNA DE RISCO (AIR)
              </li>
              <p>
                A Avaliação Interna de Risco (AIR), prevista em material
                específico, é utilizada com o objetivo de identificar e mensurar
                o risco de utilização das operações, produtos e serviços da
                instituição na lavagem de dinheiro e financiamento do
                terrorismo. A Avaliação Interna de Risco (AIR) irá considerar o
                nível de risco de lavagem de dinheiro a partir da metodologia
                desenvolvida considerando o perfil de risco correspondente ao
                modelo de negócios da instituição, dos clientes, funcionários,
                parceiros, fornecedores, operações, produtos e serviços
                ofertados.
              </p>

              <p>
                Na matriz de risco são ponderados os riscos identificados de
                acordo com a sua probabilidade de ocorrência e à magnitude do
                impacto financeiro, jurídico, reputacional, regulatório e
                social, ambiental e climático para a instituição, resultando em
                uma classificação de risco baixo, médio ou alto.
              </p>
              <p>
                Com base nessa classificação, os controles de gerenciamento e
                mitigação de riscos são desenvolvidos, sendo reforçados para
                situações de maior risco e adoção de controles mais
                simplificados para situações de menor risco.
              </p>

              <strong>PRODUTOS E SERVIÇOS</strong>
              <p>
                <br /> Refere-se aos diversos tipos de exposição à lavagem de
                dinheiro e financiamento ao terrorismo que as operações,
                transações, produtos e serviços se enquadram devido a seu perfil
                e tem como base: compra e venda de moeda estrangeira; aquisição
                de ouro; compra e venda de ouro ativo-financeiro; canais de
                distribuição e; utilização de novas tecnologias e, para isso, a{' '}
                <strong>OM DTVM</strong> avaliou o risco individualmente de
                todos os serviços e produtos oferecidos em seus canais de
                distribuição, atribuindo a cada um deles um valor de risco,
                levando em consideração os critérios destacados na Avaliação
                Interna de Risco (AIR).
              </p>
              <p>
                Para isso, visando atender o objetivo de assegurar que as
                medidas adotadas para prevenir ou mitigar os riscos de crimes
                ambientais relacionados a aquisição ilegal de ouro e/ou lavagem
                de dinheiro e financiamento do terrorismo, sejam proporcionais
                os riscos identificados no processo de aprovação do produto e
                uso de novas tecnologias, foram definidas categorias de risco
                residual (conforme destacados na Avaliação Interna de Risco
                (AIR).
              </p>
              <p>
                Leva-se em consideração para a análise de produtos, os diversos
                tipos de exposição à lavagem de dinheiro e financiamento ao
                terrorismo que as operações, transações, produtos e serviços se
                enquadram devido a seu perfil e tem como base: compra e venda de
                moeda estrangeira; aquisição de ouro; compra e venda de ouro
                ativo-financeiro; canais de distribuição e; utilização de novas
                tecnologias e, possui como base e direcionamento para a
                identificação e classificação dos riscos: a) o processo
                realizado; b) fatores de riscos associados a eles, como o risco
                geográfico e canais de distribuição utilizados; c) consequência
                de potencial risco; d) magnitude do impacto de potencial risco
                baseada pela sua probabilidade e, e) formas de controle para o
                potencial risco identificado.
              </p>
              <p>
                Já em relação às diretrizes para a análise de novas tecnologias,
                considera-se, além dos diversos tipos de exposição à lavagem de
                dinheiro e financiamento ao terrorismo que as operações,
                transações, produtos e serviços se enquadram, critérios como: a)
                impactos no processo de identificação e qualificação do cliente;
                b) transparência da tecnologia; c) eficiência operacional; d)
                melhoria da experiência do cliente; e) nível de segurança das
                informações; f) desempenho de processamento de transações e
                confiabilidade das tecnologias; g) facilidade de integração com
                sistemas existentes; h) conformidade regulatória; i)
                sustentabilidade; j) qualidade e utilidade das informações
                obtidas.
              </p>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                MONITORAMENTO, SELEÇÃO, ANÁLISE E COMUNICAÇÃO (MSAC)
              </li>
              <p>
                O monitoramento, seleção, análise e comunicação de operações
                (MSAC), previsto em manual específico, é realizado
                tempestivamente em linha com as definições emanadas pelo Banco
                Central do Brasil (BCB), Agência Nacional de Mineração (ANM) e
                Receita Federal do Brasil (RFB), estabelecendo mecanismos
                efetivos de monitoramento interno para identificar possíveis
                riscos de lavagem de dinheiro e garantir a conformidade com as
                políticas e regulamentações estabelecidas.
              </p>
              <p>
                O procedimento de monitoramento, seleção, análise de operação e
                situações suspeitas de lavagem de dinheiro e financiamento do
                terrorismo é realizado no prazo de quarenta e cinco dias,
                contados a partir da data da seleção da operação, proposta de
                operação ou situação sendo, a comunicação ao COAF, realizada até
                o dia útil seguinte após a decisão de comunicação pelo Diretor
                Executivo de PLD-FT.
              </p>
              <p>
                Todas as operações, proposta de operação e situações suspeitas
                de lavagem de dinheiro e financiamento do terrorismo são
                comunicadas, sem dar ciência aos envolvidos ou a terceiros, ao
                Conselho de Controle de Atividade Financeira – COAF, em
                consonância com a Carta Circular BCB Nº 4.001, de 29 de janeiro
                de 2020, sendo elas fundamentadas nas informações contidas no
                dossiê de reporte de forma detalhada.
              </p>
              <p>
                Caso a instituição não tenha efetuado comunicação ao COAF no ano
                civil anterior, deverá prestar declaração, até dez dias úteis
                após o encerramento do referido ano, atestando a não ocorrência
                de operações ou situações passíveis de comunicação
              </p>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                AVALIAÇÃO DE EFETIVIDADE
              </li>
              <p>
                A avaliação de efetividade, documentada em relatório específico,
                é elaborada anualmente, com data-base de 31 de dezembro e
                encaminhada, para ciência da Diretoria Executiva, até o dia 31
                de março do ano seguinte.
              </p>
              <p>
                O relatório deve avaliar a efetividade da política, dos
                procedimentos e dos controles internos de Prevenção à Lavagem de
                Dinheiro e Financiamento ao Terrorismo, em consonância com a
                Circular BCB N° 3.978, de 23 de janeiro de 2020 e conter
                informações que descrevam: a) a metodologia adotada na avaliação
                de efetividade; b) os testes aplicados pelos avaliadores; c) a
                qualificação dos avaliadores; e d) as deficiências identificadas
                pelos avaliadores.
              </p>
              <p>
                A instituição deve elaborar plano de ação destinado a solucionar
                as deficiências identificadas por meio da avaliação de
                efetividade e ser documentado por meio de relatório de
                acompanhamento. O plano de ação e o respectivo relatório de
                acompanhamento devem ser encaminhados para ciência e avaliação,
                da Diretoria Executiva, até 30 de junho do ano seguinte ao da
                data-base do relatório.
              </p>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                INDISPONIBILIDADE DE ATIVOS
              </li>
              <p>
                A <strong>OM DTVM</strong>, na adequação de seus sistemas de
                controles internos, com o objetivo de assegurar o cumprimento do
                disposto na Lei nº 13.810, de 2019 e na Resolução BCB n° 44 de
                24/11/2020, implementou solução tecnológica através do uso de
                API (Application Programming Interface - Interface de
                Programação de Aplicação) com o bureau privado da empresa Advice
                Compliance Solutions que avalia, no processo de identificação,
                se o nome do cliente se destaca presente nas resoluções do
                Conselho de Segurança das Nações Unidas (CSNU), que determinam a
                indisponibilidade de ativos de titularidade, direta ou indireta,
                de pessoas naturais, de pessoas jurídicas ou de entidades.
              </p>
              <p>
                Em caso positivo, a <strong>OM DTVM</strong> realizará a
                indisponibilidade imediata de ativos e comunicará as tentativas
                de sua transferência relacionadas às pessoas naturais, às
                pessoas jurídicas ou às entidades sancionadas por resolução do
                Conselho de Segurança das Nações Unidas (CSNU) ou por
                designações de seus comitês de sanções, nos termos do art. 11 da
                Lei nº 13.810, de 2019, ao Banco Central do Brasil, por meio do
                sistema BC Correio; Ministério da Justiça e Segurança Pública e,
                Conselho de Controle de Atividades Financeiras (COAF), na forma
                utilizada para efetivar as comunicações previstas no art. 11,
                inciso II, da Lei nº 9.613, de 3 de março de 1998.
              </p>
              <p>
                Caso a <strong>OM DTVM</strong> deixe de dar cumprimento
                imediato as medidas estabelecidas nas resoluções do Conselho de
                Segurança das Nações Unidas (CSNU) e nas designações de seus
                comitês de sanções, nos termos dos Arts. 6º a 11 da Lei nº
                13.810, de 2019, deverá comunicar ao Ministério da Justiça e
                Segurança Pública, sem demora, as razões do descumprimento da
                indisponibilidade imediata.
              </p>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                SELEÇÃO E CONTRATAÇÃO DE FUNCIONÁRIOS
              </li>
              <p>
                O processo de Know Your Employee (KYE), previsto em manual
                específico, estabelece procedimentos voltados à melhor
                compreensão do histórico e do perfil reputacional (midiático e
                processual) do funcionário, colaborador (pessoa contratada fora
                do regime CLT que executam atividades em nome da{' '}
                <strong>OM DTVM</strong>), estagiários, jovens aprendizes, bem
                como acompanhamento de sua situação financeira, visando a
                mitigação dos riscos relacionados a tais contratações.
              </p>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                PROMOÇÃO DA CULTURA
              </li>
              <p>
                A promoção de cultura de prevenção à lavagem de dinheiro e
                financiamento ao terrorismo da <strong>OM DTVM</strong> tem como
                objetivo criar uma consciência e compreensão abrangentes sobre
                os riscos associados à lavagem de dinheiro e financiamento ao
                terrorismo, envolvendo toda comunidade interna (funcionários,
                colaboradores (pessoas contratadas fora do regime CLT que
                executam atividades em nome da instituição), estagiários, jovens
                aprendizes, correspondentes cambiais e postos de atendimento) e
                promovendo uma cultura organizacional de vigilância e
                conformidade.
              </p>
              <p>
                Serão realizadas ações institucionais e/ou campanhas de
                comunicação abrangentes para promover a importância da prevenção
                à lavagem de dinheiro e financiamento ao terrorismo que incluirá
                o compartilhamento de informações relevantes, estudos de caso,
                exemplos reais e consequências legais associadas à lavagem de
                dinheiro. A liderança da <strong>OM DTVM</strong> demonstrará um
                forte compromisso com a prevenção à lavagem de dinheiro,
                estabelecendo um exemplo positivo e promovendo uma cultura de
                conformidade em toda a organização e garantirão a adesão às
                políticas e procedimentos estabelecidos. O programa de promoção
                da cultura de prevenção à lavagem de dinheiro será revisado e
                atualizado periodicamente para garantir sua relevância e
                eficácia contínuas.
              </p>
              <strong>TREINAMENTO INSTITUCIONAL </strong>
              <p>
                <br />O programa de treinamento institucional da OM DTVM abrange
                uma reciclagem, no mínimo anual, com avaliação de conhecimento,
                de toda a comunidade interna (funcionários, colaboradores
                (pessoas contratadas fora do regime CLT que executam atividades
                em nome da <strong>OM DTVM</strong>), estagiários, jovens
                aprendizes, correspondentes cambiais e postos de atendimento),
                com o objetivo de fornecer conhecimento atualizado sobre as
                regulamentações e melhores práticas de prevenção à lavagem de
                dinheiro e financiamento ao terrorismo.
              </p>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                REGISTRO DE OPERAÇÕES E DE PAGAMENTO
              </li>
              <p>
                A <strong>OM DTVM</strong> mantém o registro de todas as
                operações realizadas, produtos e serviços contratados, inclusive
                de pagamentos, recebimentos, transferências de recursos e
                operações do mercado de câmbio e ouro. No caso das informações
                relativas às operações, a <strong>OM DTVM</strong> registra, no
                mínimo, em cada operação: I – tipo; II – valor, quando
                aplicável; III – data de realização; IV – nome e número de
                inscrição no CPF ou no CNPJ do titular e do beneficiário da
                operação, no caso de pessoa residente ou sediada no País; e V –
                canal utilizado e, no caso de operações envolvendo pessoa
                natural residente no exterior desobrigada de inscrição no CPF,
                na forma definida pela Secretaria da Receita Federal do Brasil,
                registra-se: I – nome; II – tipo e número do documento de viagem
                e respectivo país emissor; III – organismo internacional de que
                seja representante para o exercício de funções específicas no
                País, quando for o caso.
              </p>
              <p>
                Já em relação às operações relativas a pagamentos, recebimentos
                e transferências de recursos, por meio de qualquer instrumento,
                a <strong>OM DTVM</strong> registra as informações necessárias à
                identificação da origem e do destino dos recursos, sendo que,
                conceitua-se como origem, à instituição pagadora, sacada ou
                remetente e à pessoa sacada ou remetente dos recursos, bem como
                ao instrumento de transferência ou de pagamento utilizado na
                transação e, como destino, a instituição recebedora ou
                destinatária e à pessoa recebedora ou destinatária dos recursos,
                bem como ao instrumento de transferência ou de pagamento
                utilizado na transação. Dito isso, a <strong>OM DTVM</strong>{' '}
                mantém registrado em seu sistema, as informações: I – nome e
                número de inscrição no CPF ou no CNPJ do remetente ou sacado; II
                – nome e número de inscrição no CPF ou no CNPJ do recebedor ou
                beneficiário; III – códigos de identificação, no sistema de
                liquidação de pagamentos ou de transferência de fundos, das
                instituições envolvidas na operação; IV – números das
                dependências e das contas envolvidas na operação.
              </p>
              <p>
                Em linha com as diretrizes emanadas pela Resolução BCB Nº 277,
                de 31 de dezembro de 2022, na operação de compra ou de venda de
                moeda estrangeira, o recebimento ou entrega do seu contravalor
                em reais deve ser realizado a partir de crédito ou de débito à
                conta de depósito ou de pagamento do cliente mantida em
                instituições financeiras e demais instituições autorizadas a
                funcionar pelo Banco Central do Brasil ou em instituições de
                pagamento que, em virtude de sua adesão ao PIX, integrem o
                Sistema de Pagamentos Brasileiro (SPB) e, quando não ultrapassar
                R$10.000,00 (dez mil reais), o recebimento ou a entrega do
                contravalor em reais, nas operações de compra e venda de moeda
                estrangeira e venda de ouro, ativo financeiro, exceto pelo meio
                de pagamento cheque, pode ser realizado por qualquer outro meio
                em uso no mercado financeiro, inclusive em espécie. Importante
                destacar que a aquisição e/ou compra de ouro, mesmo que ativo
                financeiro, não pode ser realizada por espécie.
              </p>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                GUARDA DE DOCUMENTOS
              </li>
              <p>
                A <strong>OM DTVM</strong> através de seus funcionários,
                colaboradores (pessoas contratadas fora do regime CLT que
                executam atividades em nome da <strong>OM DTVM</strong>),
                estagiários, jovens aprendizes, correspondentes cambiais e
                postos de atendimento garantem o sigilo de todas as informações
                e de todos os documentos relacionados com suas operações de ouro
                e câmbio por 10 anos.
              </p>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                SANÇÕES ADMINISTRATIVAS
              </li>
              <p>
                Os funcionários, colaboradores (pessoas contratadas fora do
                regime CLT que executam atividades em nome da{' '}
                <strong>OM DTVM</strong>), estagiários, jovens aprendizes,
                correspondentes cambiais e postos de atendimento da{' '}
                <strong>OM DTVM</strong> que não cumprirem por negligência,
                culpa ou dolo os requerimentos desta Política de Prevenção à
                Lavagem de Dinheiro e Financiamento ao Terrorismo, dependendo da
                gravidade da falta, serão advertidos oficiosamente, advertidos
                formalmente, suspensos temporariamente ou ter seu contrato
                denunciado.
              </p>
            </ul>
          </p>
        </section>
      </section>
    </article>
  );
}
